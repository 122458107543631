import { AxiosPromise } from 'axios'
import { ListBase, IDocQuery } from '../common/interface/apiQuery'
import Service from '../common/service'
import { hasMock } from '../common/utils/publicUtils'
import Config from '../common/config'
const { mockApi } = Config.app
const mockApiPrefix = `${mockApi}/customCharging`

export interface ICustomChargingQuery extends ListBase {
	docId: string
	projectName?: string
}

export interface ICustomChargingDetail {
	docId: string | number
	chargeId?: string | number
	depositPercent: string | number
	description: string | number
	discountAmount: string | number
	orderAmount: string | number
	projectName: string | number
	userType: string | number
	userId: string | number
}

class CustomChargingApi extends Service {
  /**
   * 
   * @param params 
   */
	public docList<T>(params: IDocQuery): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApiPrefix}/mainlist.json`)
		} else {
			return this.$http.post('yimai-admin/doctor/charge/mainlist', params)
		}
  }
	
	/**
	 * 
	 * @param params 
	 */
	public list<T>(params: ICustomChargingQuery): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApiPrefix}/list.json`)
		} else {
			return this.$http.post('yimai-admin/doctor/charge/chargelist', params)
		}
	}

	/**
	 * 
	 */
	public create<T>(params: ICustomChargingDetail): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApi}/success.json`)
		} else {
			return this.$http.post('yimai-admin/doctor/charge/create', params)
		}
	}

	/**
	 * 
	 * @param params 
	 */
	public delete<T>(params: { docId: string | number, ids: any[] }): AxiosPromise<T> {
		return this.$http.post('yimai-admin/doctor/charge/delete', params)
	}

	/**
	 * 
	 * @param params 
	 */
	public detail<T>(params: { docId: string | number, id: string | number }): AxiosPromise<T> {
		return this.$http.post('yimai-admin/doctor/charge/detail', params)
	}

	/**
	 * @param params
	 */
	public preview<T>(params: any): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApiPrefix}/preview.json`)
		} else {
			return this.$http.post(`yimai-admin/preview/create/getProjectInfo`, params)
		}
	}
}

export default new CustomChargingApi()