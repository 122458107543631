import Config from '../config'

function radomNumber(min: number, max: number) {
    // 如果非数字则报错
    if (typeof min !== 'number' || typeof max !== 'number') {
        throw new Error('param invalidate,should be a number')
    }
    // 如果顺序有问题则互换位置
    if (min > max) {
        ;[min, max] = [max, min]
    }
    // 如果二者相等则返回一个
    if (min === max) {
        return min
    }
    return Math.floor(Math.random() * (max - min + 1)) + min
}

function randomString(length = 6) {
    let str = 'abcdefghijklmnopqrstuvwxyz'
    str += str.toUpperCase()
    str += '0123456789'
    let newStr = ''
    for (let i = 0; i < length; i++) {
        const rand = Math.floor(Math.random() * str.length)
        newStr += str[rand]
    }
    return newStr
}

function pruneEmpty(object: any): any {
    const keys = Object.keys(object)
    const newObj = JSON.parse(JSON.stringify(object))
    for (const iterator of keys) {
        const value = object[iterator]
        if (value instanceof Array) {
            !value.length && delete newObj[iterator]
        } else if (
            value === '' ||
            value === null ||
            value === undefined ||
            value === 0
        ) {
            delete newObj[iterator]
        }
    }
    return newObj
}
function emptyFilter(data: { label: string; value: any }[]) {
    const newarr: any = []
    data.forEach(el => {
        if (el.value) {
            newarr.push(el)
        }
    })
    return newarr
}

function updateRoutePath(path: string) {
    const basePath =
        process.env.NODE_ENV === 'development'
            ? ''
            : process.env.REACT_APP_BASE_PATH
    return `${basePath}${path}`
}

export { radomNumber, randomString, pruneEmpty, emptyFilter, updateRoutePath }


/** 
 * formatMoney(s,type)
 * 功能：金额按千位逗号分割
 * 参数：s，需要格式化的金额数值.
 * 参数：type,判断格式化后的金额是否需要小数位.
 * 返回：返回格式化后的数值字符串.
 */
export function formatMoney(s: string, type?: number, prefix = '￥') {
    if (s == null || s == "")
        return `${prefix}0`
    if (Object.prototype.toString.apply(s) === '[object Number]') {
        s = s.toString()
    }

    let sign = ''
    if (s.startsWith('-')) {
        sign = '-'
        s = s.substring(1)
    }

    if (/[^0-9\.]/.test(s))
        return `${prefix}0`

    s = s.toString().replace(/^(\d*)$/, "$1.")
    s = (s + "00").replace(/(\d*\.\d\d)\d*/, "$1")
    s = s.replace(".", ",")
    var re = /(\d)(\d{3},)/
    while (re.test(s))
        s = s.replace(re, "$1,$2")

    s = s.replace(/,(\d\d)$/, ".$1")
    if (type == 0) {// 不带小数位(默认是有小数位)
        var a = s.split(".")
        if (a[1] == "00") {
            s = a[0]
        }
    }

    let result = `${prefix}${s}`

    if (sign === '-') {
        result = `${prefix}${sign}${s}`
    }

    return result
}

/**
 * 反向格式化金额
 */
export function reFormatMoney(s: string) {
    return parseFloat(s.replace(/[^\d\.-]/g, ""))
}

export const hasMock = function (isLocalMock: boolean) {
    if (isLocalMock && Config.app.isMock && process.env.NODE_ENV === 'development') {
        console.log('mockapi...')
        return true
    } else {
        return false
    }
}


export function debounce<A = unknown, R = void>(
    fn: (args: A) => R,
    delay: number
): [(args: A) => Promise<R>, () => void] {
    let timer: NodeJS.Timeout

    const debounceFn = (args: A): Promise<R> => {
        return new Promise((resolve) => {
            if (timer) {
                clearTimeout(timer)
            }
            timer = setTimeout(() => {
                resolve(fn(args));
            }, delay)
        })
    }

    const clearDebounce = () => clearTimeout(timer)
    return [debounceFn, clearDebounce]
}