import { action, computed, observable, runInAction } from 'mobx'
import FansCashoutApi, { IFansCashoutQuery, IFansCashoutDetail } from '../apis/fansCashout'
import { IDocQuery } from '../common/interface/apiQuery'
import { pruneEmpty, randomString } from '../common/utils/publicUtils'
import { handleList } from '../common/utils/handler'

class FansCashoutStore {
  @observable public list: any = {}
  @observable public detail: any = {}
  @observable public walletList: any = {}

  @action public async getList(params: IFansCashoutQuery) {
    const { data: res } = await FansCashoutApi.list(pruneEmpty(params))
    runInAction(() => {
      const { list, startRow } = res
      const nextList = handleList(list, startRow)

      const timestamp: number = Date.now()
      this.list = {...{}, ...res, ...{
        list: nextList,
        timestamp
      }}
    })
    return res
  }

  @action public async getDetail(params: { cashNo: string | number}) {
    const { data } = await FansCashoutApi.detail(params)
    runInAction(() => {
      this.detail = data
    })
    return data
  }

  @action public async confirm(params: any) {
    const { data } = await FansCashoutApi.confirm(params)
    return data
  }

  @action public async getWalletList(params: { fansId: string | number }) {
    const { data } = await FansCashoutApi.walletList(params)
    runInAction(() => {
      this.walletList = data
    })
    return data
  }
}

export default FansCashoutStore