import styled from 'styled-components'
import { Layout } from 'antd'
const { Header } = Layout

export const HeaderWrapper = styled(Header)`
	text-align: right;
	font-size: 14px;
	font-weight: 600;
	color: #fff;
	background: #5A96F9;
	position: fixed;
	right: 0;
	left: 0;
	margin-left: 200px;
  transition: margin 200ms;
	z-index: 999;
`
