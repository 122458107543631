import Axios, { AxiosPromise } from 'axios'
import {
	IRefundCreateQuery,
	IRefundMainQuery,
	IRefundQuery
} from '../common/interface/apiQuery'
import Service from '../common/service'

class RefundApi extends Service {
  public list<T>(params: IRefundQuery): AxiosPromise<T> {
    return this.$http.post('/yimai-admin/order/refund/list', params)
  }

  public create<T>(params: IRefundCreateQuery): AxiosPromise<T> {
    return this.$http.post(`/yimai-admin/order/refund/apply`, params)
  }

  public cancel<T>(params: { id: string }): AxiosPromise<T> {
    return this.$http.post(`/yimai-admin/order/refund/cancle/${params.id}`)
  }

  public detail<T>(params: { refundId: string }): AxiosPromise<T> {
    return this.$http.get(`/yimai-admin/order/refund/detail/${params.refundId}`)
  }

  public auditList<T>(params: IRefundQuery): AxiosPromise<T> {
    return this.$http.post(`/yimai-admin/order/refundAudit/orderRefundAuditList`, params)
  }

  public auditDetail<T>(params: { id: string }): AxiosPromise<T> {
    return this.$http.get(`/yimai-admin/order/refundAudit/orderRefundAuditShow/${params.id}`)
  }

  public auditAccept<T>(params: { refundId: string }): AxiosPromise<T> {
    return this.$http.post(`/yimai-admin/order/refundAudit/agreeApply`, params)
  }

  public manualRefund<T>(params: { refundId: string }): AxiosPromise<T> {
    return this.$http.get(`/yimai-admin/order/refundAudit/manualRefund/${params.refundId}`)
  }

  public auditReject<T>(params: { id: string, reason: string }): AxiosPromise<T> {
    return this.$http.post(`/yimai-admin/order/refundAudit/rejectApply`, {reason: params.reason, refundId: params.id})
  }

  public auditMainList<T>(params: IRefundMainQuery): AxiosPromise<T> {
    return this.$http.post(`/yimai-admin/order/refundAudit/mainList`, params)
  }
  
  public info<T>(params: { refundId: string}): AxiosPromise<T> {
    return this.$http.get(`/yimai-admin/order/refundAudit/applyInfo/${params.refundId}`)
  }

  public SearchDoctor<T>(params: { docName: string }): AxiosPromise<T> {
    return this.$http.post('/yimai-admin/order/common/getDoctor', params)
  }

  public SearchCommonDoctor<T>(params: { docName: string }): AxiosPromise<T> {
    return this.$http.post('/yimai-admin/common/suggest/getDoctor', params)
  }
  
}

export default new RefundApi()
