import { SelectValue } from 'antd/lib/select'
import { action, computed, observable, runInAction } from 'mobx'
import DoctorApi from '../apis/doctor'
import OrderApi from '../apis/Order'

class DoctorInfo {
	@observable public doctorList: { docName: string; id: string }[] = []
	@observable public searchDocId: SelectValue = ''
	@observable public tableData: any = {}

	@computed public get total(): number {
		return this.tableData.total
	}

	@computed public get list(): any[] {
		const { list } = this.tableData
		return list || []
	}

	@action public contentSelectHandle = (value: SelectValue) => {
		this.searchDocId = value
	}

	@action public searchDoc = async (params: { docName: string }) => {
		const { data } = await OrderApi.SearchDoctor(params)
		this.doctorList = data
	}

	@action public getList = async (
		pageNo: number = 1,
		pageSize: number = 10
	) => {
		const { data } = await DoctorApi.List({
			docId: this.searchDocId,
			pageNo,
			pageSize
		})
		this.tableData = data
	}
}

export default DoctorInfo
