import { action, computed, observable, toJS } from 'mobx'
import UserApi from '../apis/User'
import {
	IAddUserQuery,
	IAdminUserQuery,
	IUpdateUserQuery
} from '../common/interface/apiQuery'
import { handleList, preHandlerOrderDetail } from '../common/utils/handler'
import {
	emptyFilter,
	pruneEmpty,
	randomString
} from '../common/utils/publicUtils'

class UserManage {
	@observable public userId: number | undefined
	@observable public userManageData: any = {}
	@observable public roleList: any[] = []
	@observable public docList: any[] = []
	@observable public permissionList: any[] = []
	@observable public userPermission: any = {}
	@observable public isRepeatName: boolean = false
	@observable public departments: any[] = []

	@computed public get dataTotal(): number {
		return this.userManageData.total
	}
	@computed public get userManageList(): any[] {
		const { list } = this.userManageData
		return list
			? list.map((item: any) => Object.assign({ key: randomString() }, item))
			: []
	}
	@action public async getManageData(params: IAdminUserQuery) {
		const { data: res } = await UserApi.GetAdminUserList(pruneEmpty(params))
		const { list, startRow } = res
		const nextList = handleList(list, startRow)

		this.userManageData = {
			...{},
			...res,
			...{
				list: nextList
			}
		}
	}
	@action public async getRoleList() {
		const { data: res } = await UserApi.GetAllRoles()
		this.roleList = res.map((item: any) =>
			Object.assign({ key: randomString() }, item)
		)
	}
	@action public async getDepartments() {
		const { data: res } = await UserApi.GetAllDepartment()
		this.departments = res.map((item: any) =>
			Object.assign({ key: randomString() }, item)
		)
	}
	@action public async getPermissionList() {
		const { data: res } = await UserApi.GetGroupPermission()
		this.permissionList = res.map((item: any) => ({
			label: `${item.groupName}：${item.permissions}`,
			value: item.id
		}))
	}

	@action public async getAllDoctor() {
		const { data: res } = await UserApi.GetAllDoctors()
		this.docList = res
	}

	@action public async getUserPermission(params: { userid: string }) {
		try {
			const { data: res } = await UserApi.GetUserPermissionInfo(params)
			return Promise.resolve({ data: res })
		} catch (error) {
			return Promise.reject(error)
		}
	}
	@action public async checkUserName(params: { userName: string }) {
		const { data: res } = await UserApi.CheckUserName(params)
	}
	@action public async disableUser(params: { userid: string }) {
		try {
			await UserApi.DisabledUser(params)
			return Promise.resolve('success')
		} catch (error) {
			return Promise.reject(error.message)
		}
	}
	@action public async enableUser(params: { userid: string }) {
		try {
			await UserApi.EnableUser(params)
			return Promise.resolve('success')
		} catch (error) {
			return Promise.reject(error.message)
		}
	}
	@action public async unbindDoctor(params: { docid: string; userId: string }) {
		try {
			await UserApi.UnbindDoctor(params)
			return Promise.resolve('success')
		} catch (error) {
			return Promise.reject(error.message)
		}
	}

	@action public async addUser(params: IAddUserQuery) {
		try {
			const { data: res } = await UserApi.AddNewUser(pruneEmpty(params))
			return Promise.resolve(res)
		} catch (error) {
			return Promise.reject(error)
		}
	}

	@action public async updateUser(params: IUpdateUserQuery) {
		try {
			const { data: res } = await UserApi.UpdateUser(pruneEmpty(params))
			return Promise.resolve(res)
		} catch (error) {
			return Promise.reject(error)
		}
	}
	@action public async resetPwd(params: { pwd?: string; userId: number }) {
		try {
			await UserApi.resetPwd(params)
			return Promise.resolve()
		} catch (error) {
			return Promise.reject(error)
		}
	}
}
export default UserManage
