import {
	Breadcrumb,
	Button,
	Col,
	Form,
	Icon,
	Input,
	Layout,
	message,
	Modal,
	Row
} from 'antd'
import { autobind } from 'core-decorators'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { ChangeEvent, Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { IAuth, IHeader } from '../../common/interface'
import { updateRoutePath } from '../../common/utils/publicUtils'
import Routes from '../../router/Routes'
import BreadcrumbContainer from '../Layout/Breadcrumb'
import MHeader from '../Layout/Header'
import SideBar from '../Layout/SideBar'
import { ColBox, ContentView, ContentWrapper, PageHeader } from './style'
import _ from 'lodash'
import {
  DOCTOR_CASE_DEPOSIT_DETAIL_PATH
} from '../../common/constants/path'

interface AppProps extends IAuth {
	header: IHeader
	global: any
}
interface PathType {
	[key: string]: string
}

interface IAppState {
	currentOpenKeys: string[]
}

@inject('auth', 'router', 'header', 'global')
@autobind
@observer
class App extends Component<AppProps, IAppState> {
	constructor(props: AppProps, state: IAppState) {
		super(props, state)
		this.state = {
			currentOpenKeys: ['']
		}
	}

	private getCurrentMenu = (pathname: string) => {
		let newPathname
		switch (pathname) {
			case '/app/userOrder':
			case '/app/orderDetail':
				newPathname = '/app/order'
				break

			case '/app/makeupRecord':
			case '/app/createMakeup':
			case '/app/makeupReview':
				newPathname = '/app/makeup'
				break

			case '/app/mainRefund':
			case '/app/refund':
			case '/app/refundDetail':
				newPathname = '/app/mainRefund'
				break

			case '/app/docOrderSettle':
			case '/app/orderSettleDetail':
				newPathname = '/app/orderSettle'
				break

			case '/app/settleDetail':
				newPathname = '/app/withdrawSettle'
				break

			case '/app/reviewDetail':
				newPathname = '/app/withdrawReview'
				break

			case '/app/userAllMakeup':
			case '/app/makeupReviewDetail':
				newPathname = '/app/financeReview'
				break

			case '/app/configInfo/1':
			case '/app/configInfo/2':
			case '/app/configInfo/3':
			case '/app/settingDetail':
				newPathname = '/app/configInfo/1'
				break

			case '/app/auditMainRefund':
			case '/app/auditRefund':
			case '/app/auditRefundDetail':
				newPathname = '/app/auditMainRefund'
				break

			case '/app/userRegister':
				newPathname = '/app/userManage'
				break
			case '/app/mainCustomCharging':
			case '/app/customCharging':
			case '/app/customChargingDetail':
				newPathname = '/app/mainCustomCharging'
				break
			case '/app/doctorinfo':
			case '/app/configProfile':
			case '/app/haodf':
				newPathname = '/app/doctorinfo'
				break
			case '/app/mainContent':
			case '/app/content':
			case '/app/contentDetail':
			case '/app/contentItem':
				newPathname = '/app/mainContent'
				break
			case '/app/roleAdmin':
			case '/app/createRole':
				newPathname = '/app/roleAdmin'
				break
			case '/app/fans/cashout':
			case '/app/fans/cashout/detail':
				newPathname = '/app/fans/cashout'
				break
			case '/app/doctor/settle/list':
			case '/app/doctor/settle/detail':
			case '/app/doctor/settle/waiting/list':
				newPathname = '/app/doctor/settle/list'
				break
			case '/app/doctor/case/deposit/list':
			case `${DOCTOR_CASE_DEPOSIT_DETAIL_PATH}`:
				newPathname = '/app/doctor/case/deposit/list'
				break
			default:
				newPathname = pathname
				break
		}
		return newPathname
	}

	private getCurrentOpenKeys = (pathname: string) => {
		let currentOpenKeys = ['']
		const pathSnippets = pathname.split('/').filter((i: any) => i)
		const startIndex = process.env.NODE_ENV === 'development' ? 0 : 1
		const curMenu = `/${pathSnippets
			.slice(startIndex, pathSnippets.length)
			.join('/')}`

		const { Menus: menus } = this.props.auth

		for (let i = 0; i < menus.length; i++) {
			const { children } = menus[i]
			const findItem = (children as Array<any>).filter((item: any) => {
				return item.path.trim() === curMenu
			})
			if (!_.isEmpty(findItem)) {
				currentOpenKeys = [`${i}`]
				break
			}
		}

		this.setState({
			currentOpenKeys
		})
		return
		switch (curMenu) {
			case '/app/order':
			case '/app/userOrder':
			case '/app/orderDetail':

			case '/app/makeup':
			case '/app/makeupRecord':
			case '/app/createMakeup':
			case '/app/makeupReview':

			case '/app/mainRefund':
			case '/app/refund':
			case '/app/refundDetail':
				currentOpenKeys = ['1']
				break

			case '/app/mainCustomCharging':
			case '/app/customCharging':
			case '/app/customChargingDetail':

			case '/app/doctorinfo':
			case '/app/configProfile':
			case '/app/haodf':

			case '/app/mainContent':
			case '/app/content':
			case '/app/contentDetail':
			case '/app/contentItem':
				currentOpenKeys = ['0']
				break

			case '/app/orderSettle':
			case '/app/docOrderSettle':
			case '/app/orderSettleDetail':
			case '/app/withdrawSettle':
			case '/app/settleDetail':
			case '/app/withdrawReview':
			case '/app/makeupReviewDetail':
			case '/app/reviewDetail':
			case '/app/financeReview':
			case '/app/userAllMakeup':

			case '/app/configInfo/1':
			case '/app/configInfo/2':
			case '/app/configInfo/3':
			case '/app/settingDetail':

			case '/app/auditMainRefund':
			case '/app/auditRefund':
			case '/app/auditRefundDetail':
				currentOpenKeys = ['2']
				break

			case '/app/userManage':
			case '/app/userRegister':
			case '/app/remind':
			case '/app/roleAdmin':
			case '/app/createRole':
				currentOpenKeys = ['3']
				break
			default:
				break
		}

		this.setState({
			currentOpenKeys
		})
	}

	public async componentDidMount() {
		await this.props.auth.getMenuData()
		setTimeout(() => {
			this.getCurrentOpenKeys(this.props.location.pathname)
		}, 250)
	}

	public menuOpenHandle = (keys: string[]) => {
		this.setState({
			currentOpenKeys: keys
		})
	}

	public renderDevTool() {
		if (process.env.NODE_ENV !== 'production') {
			// tslint:disable-next-line:no-require-imports
			const DevTools = require('mobx-react-devtools').default
			return <DevTools />
		}
		return null
	}
	public filterPathname = (pathname: string) => {
		const pathSnippets = pathname.split('/').filter((i: any) => i)
		const startIndex = process.env.NODE_ENV === 'development' ? 0 : 1
		const curMenu = `/${pathSnippets
			.slice(startIndex, pathSnippets.length)
			.join('/')}`

		const newCurMenu = this.getCurrentMenu(curMenu)
		return newCurMenu
	}
	public async componentWillMount() {
		// await this.props.auth.getMenuData()
		this.props.header.setTotalNum()

		console.log(this.props.history.location)
		const { pathname } = this.props.history.location

		if (pathname !== updateRoutePath('/app')) return

		const { Menus } = this.props.auth
		const firstMenu = _.first(Menus)
		const { children } = firstMenu
		let firstChildren
		if (!_.isEmpty(children)) {
			firstChildren = _.first(children)
		}

		if (!_.isEmpty(firstChildren)) {
			const { path } = firstChildren as any
			this.props.history.push({
				pathname: updateRoutePath(path.trim())
			})
			setTimeout(() => {
				this.getCurrentOpenKeys(this.props.location.pathname)
			}, 250)
		}

	}
	private navigatorTo = () => {
		this.props.history.push({
			pathname: updateRoutePath('/app/userInfo')
		})
	}

	public render() {
		return (
			<Layout style={{ height: '100vh' }}>
				<SideBar
					currentKey={this.filterPathname(this.props.location.pathname)}
					currentOpenKeys={this.state.currentOpenKeys}
					menuOpenHandle={this.menuOpenHandle}
					menuData={this.props.auth.Menus}
				/>
				<Layout>
					<MHeader header={this.props.header} />
					<ContentWrapper>
						<PageHeader>
							<BreadcrumbContainer
								location={this.props.location}
								pageTitle={
									this.props.global.currentPageDoctor
								}></BreadcrumbContainer>
							{this.props.location.pathname ===
								updateRoutePath('/app/userInfo') ? null : (
								<Button onClick={this.navigatorTo}>
									<Icon type="setting" />
									个人设置
								</Button>
							)}
						</PageHeader>
						<ContentView>
							<Routes />
						</ContentView>
					</ContentWrapper>
				</Layout>
				{this.renderDevTool()}
			</Layout>
		)
	}
}

export default App
