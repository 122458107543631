import { message } from 'antd'
import { action, computed, observable, runInAction } from 'mobx'
import DoctorApi from '../apis/doctor'
import { history } from '../containers/Root/Root'

interface IInfo {
	activityId: string
	docId: string
	id: string
	qcodeUrl: string
	voteUrl: string
}
class GoodDoctor {
	@observable public info: IInfo = {
		activityId: '1',
		docId: '',
		id: '',
		qcodeUrl: '',
		voteUrl: ''
	}
	@observable public docId: string = ''
	@observable public previewInfo: any = {}
	@computed public get isDisabled(): boolean {
		return !this.info.voteUrl || !this.info.qcodeUrl
	}

	@action public updateInfo = (field: string, value: string) => {
		;(this.info as { [key: string]: any })[field] = value
	}
	@action public updateDocid = (id: string) => {
		runInAction(() => {
			this.docId = id
		})
	}
	@action public getInfo = async () => {
		const { data } = await DoctorApi.GdInfo({ docId: this.docId })
		this.info = Object.assign({}, this.info, data)
	}

	@action public saveInfo = async () => {
		try {
			await DoctorApi.UpdateGdInfo(
				Object.assign({}, this.info, { docId: this.docId, activityId: '1' })
			)
			message.success('修改成功')
			history.goBack()
		} catch (error) {}
	}

	@action public getPreviewInfo = async () => {
		const { data } = await DoctorApi.HaodfPreview(
			Object.assign({}, this.info, { activityId: 1 })
		)
		runInAction(() => {
			this.previewInfo = data
		})
		return data
	}

	@action public freed = () => {
		const keys = Object.keys(this.info)
		for (const key in keys) {
			if (this.info.hasOwnProperty(keys[key])) {
				;(this.info as { [key: string]: any })[keys[key]] = ''
			}
		}
	}
}
export default GoodDoctor
