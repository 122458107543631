import { settleCalcWay, settleListStatus, settleStatus } from '../enums'

export const PENDING_PAYMENT = 10
export const PAYMENT_SUCCESS = 20
export const PENDING_CONFIRMATION_OF_ORDER = 30
export const ORDER_COMPLETION = 40
export const REFUND = 50
export const CANCELED = 60
export const WAITING_TIME = 70
export const IS_CLOSED = 90
export const ORDER_APPOINT_TYPE = 1
export const ORDER_OPERA_TYPE = 2
export const ORDER_PRO_TYPE = 3

export const ORDER_STATUS = {
	[PENDING_PAYMENT]: '待支付',
	[PAYMENT_SUCCESS]: '支付成功',
	[PENDING_CONFIRMATION_OF_ORDER]: '待确认订单',
	[ORDER_COMPLETION]: '订单完成',
	[REFUND]: '退款',
	[CANCELED]: '取消',
	[WAITING_TIME]: '待约时间',
	[IS_CLOSED]: '已关闭'
}

export const ORDER_BTN_CONF: any = {
	[ORDER_APPOINT_TYPE]: {
		update_order: {
			[PENDING_PAYMENT]: true,
			[PAYMENT_SUCCESS]: true,
			[CANCELED]: true
		},
		refound: {
			[PAYMENT_SUCCESS]: true,
			[ORDER_COMPLETION]: true
		},
		confirm: {}
	},
	[ORDER_OPERA_TYPE]: {
		update_order: {
			[PENDING_PAYMENT]: true,
			[PAYMENT_SUCCESS]: true
		},
		refound: {
			[PAYMENT_SUCCESS]: true,
			[ORDER_COMPLETION]: true,
			[PENDING_CONFIRMATION_OF_ORDER]: true
		},
		confirm: {
			[PENDING_CONFIRMATION_OF_ORDER]: true
		}
	},
	[ORDER_PRO_TYPE]: {
		update_order: {
			[PAYMENT_SUCCESS]: true
		},
		refound: {
			[PAYMENT_SUCCESS]: true,
			[ORDER_COMPLETION]: true,
			[PENDING_CONFIRMATION_OF_ORDER]: true,
			[WAITING_TIME]: true
		},
		confirm: {
			[PENDING_CONFIRMATION_OF_ORDER]: true
		}
	}
}

export const SUB_PENDING_PAYMENT_PENDING_CONFIRMATION = 11
export const SUB_WAITING_FOR_A_CONSULTATION_PENDING_SURGERY = 20
export const SUB_MODIFIED_IN = 21
export const SUB_PENDING_REGISTRATION = 22
export const SUB_FANS_WAITING_TO_BE_CONFIRMED = 23
export const SUB_UNTIL_THE_DOCTOR_CONFIRMS = 30
export const SUB_ORDER_COMPLETION = 40
export const SUB_PENDING_REFUNDS = 51
export const SUB_CANCELLATION_SUCCEEDED = 61
export const SUB_WAITING_TIME = 70
export const SUB_FANS_CONFIRM_TIMEOUT = 71
export const SUB_PENDING_REGISTRATION_V2 = 72
export const SUB_TIMEOUT_SHUTDOWN = 91

export const ORDER_SUB_STATUS = {
	[SUB_PENDING_PAYMENT_PENDING_CONFIRMATION]: '待支付/确认',
	[SUB_WAITING_FOR_A_CONSULTATION_PENDING_SURGERY]: '待面诊/手术',
	[SUB_MODIFIED_IN]: '修改中',
	[SUB_PENDING_REGISTRATION]: '待登记',
	[SUB_FANS_WAITING_TO_BE_CONFIRMED]: '粉丝待确认',
	[SUB_UNTIL_THE_DOCTOR_CONFIRMS]: '待医生确认',
	[SUB_ORDER_COMPLETION]: '订单完成',
	[SUB_PENDING_REFUNDS]: '待退款',
	[SUB_CANCELLATION_SUCCEEDED]: '取消成功',
	[SUB_WAITING_TIME]: '待约时间',
	[SUB_FANS_CONFIRM_TIMEOUT]: '粉丝确认超时',
	[SUB_PENDING_REGISTRATION_V2]: '待登记',
	[SUB_TIMEOUT_SHUTDOWN]: '超时关闭'
}

/**
 * 
 */
export const AnnounceType = {
	'1': '科学科普',
	'2': '诊前须知',
	'3': '诊后必读',
	'4': '其他'
}

/**
 * 结算计算方式描述
 */
export const settleCalcWayDesc = {
	[settleCalcWay.normal]: '公式一：支付-(订单总额*平台比例)',
	[settleCalcWay.spacial]: '公式二：支付金额*医生比例',
	[settleCalcWay.mutilHospital]: '公式三：订单总额*多点医生比例'
}

/**
 * 结算状态描述
 */
export const settleStatusDesc = {
	[settleStatus.waitSettle]: '待结算',
	[settleStatus.waitAudit]: '待财务确认',
	[settleStatus.audited]: '财务已确认',
	[settleStatus.done]: '已推送',
	[settleStatus.waitDoctorSettle]: '待医生结算'
}

export const settleListStatusDesc = {
	[settleListStatus.waitBuild]: '待生成',
	[settleListStatus.waitComfirm]: '待确认',
	[settleListStatus.waitPush]: '待推送',
	[settleListStatus.done]: '已完成'
}
