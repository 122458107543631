import { Form, Icon, Input, message } from 'antd'
import { autobind } from 'core-decorators'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import UserApi from '../../apis/User'
import Logo from '../../assets/images/ym_logo.png'
import { IAuth, ISignIn } from '../../common/interface'
import { updateRoutePath } from '../../common/utils/publicUtils'
import { ButtonWrapper, FormWrapper, LoginWrapper } from './style'
interface LoginState extends ISignIn { }

@inject('auth', 'router')
@autobind
@observer
class Login extends Component<IAuth, LoginState> {
	constructor(props: IAuth, state: LoginState) {
		super(props, state)
		this.state = {
			username: '',
			password: ''
		}
	}
	public async handleSubmit(event: any) {
		event.preventDefault()
		try {
			await this.props.auth.signIn({
				username: this.state.username,
				password: this.state.password
			})

			message.success('登陆成功')
			this.props.history.replace(updateRoutePath('/app'))
		} catch (error) {
			message.error((error as any).message)
		}
	}
	public changeHandle(event: any, type: string) {
		const state = Object.create(null)
		state[type] = event.target.value
		this.setState(state)
	}
	public render() {
		return (
			<LoginWrapper>
				<FormWrapper onSubmit={this.handleSubmit} className='login-form'>
					<div className='logo'>
						<img src={Logo} alt='' />
						<span>医脉</span>
					</div>
					<Form.Item>
						<Input
							prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
							value={this.state.username}
							size='large'
							placeholder='工号'
							onChange={(e: any) => this.changeHandle(e, 'username')}
						/>
					</Form.Item>
					<Form.Item>
						<Input
							prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
							value={this.state.password}
							size='large'
							type='password'
							placeholder='密码'
							onChange={(e: any) => this.changeHandle(e, 'password')}
						/>
					</Form.Item>
					<Form.Item>
						<ButtonWrapper type='primary' htmlType='submit'>
							登 录
						</ButtonWrapper>
					</Form.Item>
					<p className='tips'>让美更简单 让工作更高效</p>
				</FormWrapper>
				<div className='bottom-text'></div>
			</LoginWrapper>
		)
	}
}

export default Login
