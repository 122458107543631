export default {
    QINIUCND: 'https://cdn.17mei.cn/',
    qiniu: {
        AK: 'TeTggy8WqhS8CQ8f_AFqUOsWxuurZKxXbsTMTNU_',
        SK: '9qCgtaFznc_uM1I41HZ-UOXhWAfV5mEQ1t_ltJD7',
        bucket: 'part-of-wechat',
        region: 'http://upload.qiniup.com',
        domain: 'https://cdn.17mei.cn/'
    },
    app: {
        isMock: true,
        mockApi: 'http://localhost:3000/mockapi'
    }
}
