import { action, computed, observable, runInAction } from 'mobx'
import RefundApi from '../apis/Refund'
import { pruneEmpty, randomString } from '../common/utils/publicUtils'
import { IRefundQuery, IRefundCreateQuery, IRefundMainQuery } from '../common/interface/apiQuery'
import { handleList } from '../common/utils/handler'

class RefundStore {
  @observable public data: any
  @observable public detail: any
  @observable public auditList: any
  @observable public auditDetail: any
  @observable public mainList: any
  @observable public info: any
  @observable public completeData: any[]
  @observable public DoctorSuggesData: any[]
  @observable public createResult: any

  @action public async getList(params: IRefundQuery) {
    const { data: res } = await RefundApi.list(pruneEmpty(params))
    runInAction(() => {
      const { list, startRow } = res
      const nextList = handleList(list, startRow)

      const timestamp: number = Date.now()
      this.data = {
        ...{}, ...res, ...{
          list: nextList,
          timestamp
        }
      }
    })
    return res
  }

  @action public async create(params: IRefundCreateQuery) {
    const { data: res } = await RefundApi.create(params)
    runInAction(() => {
      this.createResult = res
    })
    return res
  }

  @action public async cancel(params: { id: string }) {
    const { data: res } = await RefundApi.cancel(params)
    return res
  }

  @action public async getDetail(params: { refundId: string }) {
    const { data: res } = await RefundApi.detail(params)
    runInAction(() => {
      const timestamp: number = Date.now()
      const detail = this.detail
      this.detail = {
        ...{}, detail, ...res, ...{
          timestamp
        }
      }
    })
    return res
  }

  @action public async getAuditList(params: IRefundQuery) {
    const { data: res } = await RefundApi.auditList(pruneEmpty(params))

    runInAction(() => {
      const timestamp: number = Date.now()
      const { list, startRow } = res
      const nextList = handleList(list, startRow)
      this.auditList = {
        ...{}, ...res, ...{
          list: nextList,
          timestamp
        }
      }
    })
    return res
  }

  @action public async getAuditDetail(params: { id: string }) {
    const { data: res } = await RefundApi.auditDetail(params)
    runInAction(() => {
      const timestamp: number = Date.now()
      this.auditDetail = {
        ...{}, ...res, ...{
          timestamp
        }
      }
    })
    return res
  }

  @action public async auditAccept(params: { refundId: string, doctorRefund: number, platformRefund: number }) {
    const { data: res } = await RefundApi.auditAccept(params)
    return res
  }

  @action public async manualRefund(params: { refundId: string, doctorRefund: number, platformRefund: number }) {
    const { data: res } = await RefundApi.manualRefund(params)
    return res
  }
  


  @action public async auditReject(params: { id: string, reason: string }) {
    const { data: res } = await RefundApi.auditReject(params)
    return res
  }

  @action public async getAuditMainList(params: IRefundMainQuery) {
    const { data: res } = await RefundApi.auditMainList(params)
    runInAction(() => {
      const { list, startRow } = res
      const nextList = handleList(list, startRow)
      this.mainList = {
        ...{}, ...res, ...{
          list: nextList
        }
      }
    })
    return res
  }

  @action public async getInfo(params: { refundId: string }) {
    const { data: res } = await RefundApi.info(params)
    runInAction(() => {
      this.info = res
    })
    return res
  }

  @action public async getDoctorSuggest(params: { docName: string }) {
    const { data: res } = await RefundApi.SearchDoctor(params)
    runInAction(() => {
      this.completeData = res.map(
        (c: { id: number; docName: string } | null) => {
          const obj = c && {
            value: c.id,
            text: c.docName
          }
          return obj
        }
      )
    })
    return res
  }

  @action public async getCommonDoctorSuggest(params: { docName: string }) {
    const { data: res } = await RefundApi.SearchCommonDoctor(params)

    runInAction(() => {
      this.DoctorSuggesData = res.map(
        (c: { id: number; docName: string, wechatName: string } | null) => {
          const obj = c && {
            value: c.id,
            text: `${c.docName}/${c.wechatName}`
          }
          return obj
        }
      )
    })
    return res
  }

  constructor() {
    this.data = {}
    this.detail = {
      timestamp: Date.now()
    }
    this.auditList = {}
    this.auditDetail = {}
    this.mainList = {}
    this.info = {}
    this.completeData = []
    this.DoctorSuggesData = []
  }
}

export default RefundStore
