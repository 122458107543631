import { action, computed, observable } from 'mobx'
import UserApi from '../apis/User'
class MHeader {
    @observable public totalNum: number
    @observable public enterDays: number

    @computed public get getTotal() {
        return this.totalNum
    }
    @action public async setTotalNum(num: number = 0) {
        const { data: res } = await UserApi.GetDocNum()
        this.totalNum = res.doc_count
        this.enterDays = res.enter_days
    }
    constructor() {
        this.totalNum = 0
        this.enterDays = 0
    }
}

export default MHeader
