import styled from 'styled-components'
import { Layout, Col } from 'antd'
import * as colors from '../../common/styles/variable'
const { Content } = Layout
export const LayoutWrapper = styled.div`
	height: 100%;
`

export const ContentWrapper = styled(Content)`
	box-sizing: border-box;
	padding: 15px 20px;
	margin-top: 70px;
`

export const PageHeader = styled.div`
	box-sizing: border-box;
	background: #fff;
	padding: 15px 10px;
	display: flex;
	justify-content: space-between;
	align-items: middle;
	border-bottom: 1px solid ${colors.$border_color};
`

export const ContentView = styled.div`
	box-sizing: border-box;
	background: #fff;
	padding: 20px;
`

export const ColBox = styled(Col)`
	font-size: 16px;
	color: ${colors.$gray3};
	line-height: 1.8;
	padding: 5px 0;
	.required {
		color: red;
	}
`
