/**
 * 日期时间格式化字符串
 */
export const DATE_FORMAT_YYYYMMDD = 'YYYY-MM-DD'
export const DATE_FORMAT_YYYYMMDD_PIPE_HHMM = 'YYYY-MM-DD | HH:mm'
export const DATE_FORMAT_YYYYMMDD_HHMM = 'YYYY-MM-DD HH:mm'
export const DATE_FORMAT_YYYYMM = 'YYYY-MM'
export const DATE_FORMAT_YYYYMMDD_PIPE_HHMMSS = 'YYYY-MM-DD | HH:mm:ss'

/**
 * 支付方式
 */
export const PAY_CHANNEL_WXPAY = 1
export const PAY_CHANNEL_ALIPAY = 2
export const PAY_CHANNEL_LIANLIANPAY = 3
export const PAY_CHANNEL_OFFLINE = 4

/**
 * 超时时间 小时
 */
export const EXPIRE_HOUR = 2

/**
 * 粉丝类型
 */
export const FANS_SOURCE_UNDEFINED = 0 // 未知
export const FANS_SOURCE_CLINIC = 1 // 门诊
export const FANS_SOURCE_CONSULT = 2 // 口碑


/**
 * 分页默认选项
 */
export const paginationDefaultOptions = {
  pageNo: 1,
  pageSize: 20
}

/**
 * 分页显示每页条数选项
 */
export const paginationShowSizeOptions = {
  showSizeChanger: true,
  pageSizeOptions: ['20', '40', '60', '80', '100']
}

/**
 * 订单是否可以退款
 */
export const ORDER_REFUND_ENABLE = 1 //显示
export const ORDER_REFUND_DISENABLE = 2//不显示

/**
 * 表单项目布局样式
 */
export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { offset: 1, span: 3 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  }
}

/**
 * 限制请允许的文件类型
 */
export const ACCEPT_ALL_IMAGE = 'image/*' //所有图片
export const ACCEPT_ALL_VIDEO = 'video/*' //所有视频
