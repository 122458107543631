import { Breadcrumb } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { updateRoutePath } from '../../../common/utils/publicUtils'
import {
  DOCTOR_CASE_DEPOSIT_DETAIL_PATH
} from '../../../common/constants/path'

const { Item: BreadcrumbItem } = Breadcrumb

interface IProps {
	location: any
	pageTitle: string | ''
}

interface IState {
	currentBreadcrumb: any[]
}

const rootBreadcrumb = [
	{
		name: '首页',
		icon: '',
		href: '/app'
	}
]

const order = [
	{
		name: '订单管理',
		icon: '',
		href: '/app/order'
	}
]

const userOrder = [
	{
		name: '医生订单管理',
		icon: '',
		href: ''
	}
]

const orderDetail = [
	{
		name: '订单详情',
		icon: '',
		href: ''
	}
]

const makeup = [
	{
		name: '补单管理',
		icon: '',
		href: '/app/makeup'
	}
]

const createMakeup = [
	{
		name: '创建补单',
		icon: '',
		href: '/app/createMakeup'
	}
]

const makeupRecord = [
	{
		name: '医生补单管理',
		icon: '',
		href: ''
	}
]

const makeupReview = [
	{
		name: '补单详情',
		icon: '',
		href: ''
	}
]

const orderSettle = [
	{
		name: '订单结算',
		icon: '',
		href: '/app/orderSettle'
	}
]

const docOrderSettle = [
	{
		name: '医生订单结算',
		icon: '',
		href: ''
	}
]

const docOrderSettleDetail = [
	{
		name: '订单结算详情',
		icon: '',
		href: ''
	}
]

const withdrawSettle = [
	{
		name: '提现结算',
		icon: '',
		href: '/app/withdrawSettle'
	}
]

const settleDetail = [
	{
		name: '提现结算详情',
		icon: '',
		href: ''
	}
]

const withdrawReview = [
	{
		name: '提现审核',
		icon: '',
		href: '/app/withdrawReview'
	}
]

const reviewDetail = [
	{
		name: '提现审核详情',
		icon: '',
		href: '/app/reviewDetail'
	}
]

const financeReview = [
	{
		name: '补单审核',
		icon: '',
		href: '/app/financeReview'
	}
]

const userAllMakeup = [
	{
		name: '医生补单审核',
		icon: '',
		href: ''
	}
]

const makeupReviewDetail = [
	{
		name: '补单审核详情',
		icon: '',
		href: ''
	}
]

const configInfo = [
	{
		name: '配置信息',
		icon: '',
		href: '/app/configInfo/1'
	}
]

const settingDetail = [
	{
		name: '配置详情',
		icon: '',
		href: ''
	}
]

const refundMainList = [
	{
		name: '退款申请',
		icon: '',
		href: '/app/mainRefund'
	}
]

const refundList = [
	{
		name: '申请列表',
		icon: '',
		href: ''
	}
]

const refundDetail = [
	{
		name: '退款详情',
		icon: '',
		href: ''
	}
]

const auditMainRefund = [
	{
		name: '退款审核',
		icon: '',
		href: '/app/auditMainRefund'
	}
]

const auditRefund = [
	{
		name: '审核列表',
		icon: '',
		href: ''
	}
]

const auditRefundDetail = [
	{
		name: '医生退款详情',
		icon: '',
		href: ''
	}
]

const userManage = [
	{
		name: '用户管理',
		icon: '',
		href: '/app/userManage'
	}
]

const userRegister = [
	{
		name: '用户详情',
		icon: '',
		href: ''
	}
]

const remind = [
	{
		name: '提醒',
		icon: '',
		href: '/app/remind'
	}
]
const userSetting = [
	{
		name: '个人设置',
		icon: '',
		href: '/app/userInfo'
	}
]

const mainCustomChargingList = [
	{
		name: '收费项目',
		icon: '',
		href: '/app/mainCustomCharging'
	}
]

const CustomChargingList = [
	{
		name: '项目列表',
		icon: '',
		href: ''
	}
]

const CustomChargingDetail = [
	{
		name: '详情',
		icon: '',
		href: ''
	}
]

const mainContentList = [
	{
		name: '内容管理',
		icon: '',
		href: '/app/content'
	}
]

const ContentList = [
	{
		name: '内容列表',
		icon: '',
		href: ''
	}
]

const ContentDetail = [
	{
		name: '详情',
		icon: '',
		href: ''
	}
]

const ContentItem = [
	{
		name: '案例阶段',
		icon: '',
		href: ''
	}
]

const RoleAdmin = [
	{
		name: '角色管理',
		icon: '',
		href: '/app/roleAdmin'
	}
]
const CreateRole = [
	{
		name: '角色详情',
		icon: '',
		href: '/app/createRole'
	}
]
const DoctorInfo = [
	{
		name: '医生资料',
		icon: '',
		href: '/app/doctorinfo'
	}
]
const ConfigProfile = [
	{
		name: '配置个人资料',
		icon: '',
		href: '/app/configProfile'
	}
]

const GoodDoctor = [
	{
		name: '配置好大夫推广资料',
		icon: '',
		href: '/app/haodf'
	}
]

const FansCashoutAudit = [
	{
		name: '粉丝提现',
		icon: '',
		href: '/app/fans/cashout'
	}
]

const FansCashoutAuditDetail = [
	{
		name: '详情',
		icon: '',
		href: ''
	}
]

const DoctorQRCode = [
	{
		name: '医生二维码',
		icon: '',
		href: '/app/doctor/qr-code'
	}
]

const DoctorSettleList = [
	{
		name: '医生结算',
		icon: '',
		href: '/app/doctor/settle/list'
	}
]

const DoctorSettleDetail = [
	{
		name: '详情',
		icon: '',
		href: ''
	}
]

const WaitingDoctorSettleList = [
	{
		name: '待医生分账列表',
		icon: '',
		href: ''
	}
]

const DoctorCaseDepositList = [
	{
		name: '医生保证金',
		icon: '',
		href: '/app/doctor/case/deposit/list'
	}
]

const DoctorCaseDepositDetail = [
	{
		name: '详情',
		icon: '',
		href: ''
	}
]

const breadcrumb = {}

@observer
class BreadcrumbContainer extends Component<IProps, IState> {
	constructor(props: IProps, state: IState) {
		super(props)
		this.state = {
			currentBreadcrumb: []
		}
	}

	public componentDidMount() { }

	private getCurrentBreadcrumb = () => {
		const { state } = this.props.location
		const curMenu = this.getCurrentMenu()
		let currentBreadcrumb: any[] = []

		switch (curMenu) {
			case '/app/order':
				currentBreadcrumb = rootBreadcrumb.concat(order)
				break
			case '/app/userOrder':
				userOrder[0].name = this.props.pageTitle
				currentBreadcrumb = rootBreadcrumb.concat(order.concat(userOrder))
				break
			case '/app/orderDetail':
				currentBreadcrumb = rootBreadcrumb.concat(
					order.concat(userOrder.concat(orderDetail))
				)
				break
			case '/app/makeup':
				currentBreadcrumb = rootBreadcrumb.concat(makeup)
				break
			case '/app/createMakeup':
				if (!!state && !!state.docid) {
					currentBreadcrumb = rootBreadcrumb.concat(
						makeup.concat(makeupRecord.concat(createMakeup))
					)
					break
				}
				currentBreadcrumb = rootBreadcrumb.concat(makeup.concat(createMakeup))
				break
			case '/app/makeupRecord':
				makeupRecord[0].name = this.props.pageTitle
				currentBreadcrumb = rootBreadcrumb.concat(makeup.concat(makeupRecord))
				break
			case '/app/makeupReview':
				currentBreadcrumb = rootBreadcrumb.concat(
					makeup.concat(makeupRecord.concat(makeupReview))
				)
				break
			case '/app/orderSettle':
				currentBreadcrumb = rootBreadcrumb.concat(orderSettle)
				break
			case '/app/docOrderSettle':
				docOrderSettle[0].name = this.props.pageTitle
				currentBreadcrumb = rootBreadcrumb.concat(
					orderSettle.concat(docOrderSettle)
				)
				break
			case '/app/withdrawSettle':
				currentBreadcrumb = rootBreadcrumb.concat(withdrawSettle)
				break
			case '/app/settleDetail':
				currentBreadcrumb = rootBreadcrumb.concat(
					withdrawSettle.concat(settleDetail)
				)
				break
			case '/app/withdrawReview':
				currentBreadcrumb = rootBreadcrumb.concat(withdrawReview)
				break
			case '/app/reviewDetail':
				currentBreadcrumb = rootBreadcrumb.concat(
					withdrawReview.concat(reviewDetail)
				)
				break
			case '/app/financeReview':
				currentBreadcrumb = rootBreadcrumb.concat(financeReview)
				break
			case '/app/userAllMakeup':
				currentBreadcrumb = rootBreadcrumb.concat(
					financeReview.concat(userAllMakeup)
				)
				break
			case '/app/makeupReviewDetail':
				currentBreadcrumb = rootBreadcrumb.concat(
					financeReview.concat(userAllMakeup.concat(makeupReviewDetail))
				)
				break
			case '/app/configInfo/1':
			case '/app/configInfo/2':
			case '/app/configInfo/3':
				currentBreadcrumb = rootBreadcrumb.concat(configInfo)
				break
			case '/app/settingDetail':
				currentBreadcrumb = rootBreadcrumb.concat(
					configInfo.concat(settingDetail)
				)
				break
			case '/app/mainRefund':
				currentBreadcrumb = rootBreadcrumb.concat(refundMainList)
				break
			case '/app/refund':
				currentBreadcrumb = rootBreadcrumb.concat(
					refundMainList.concat(refundList)
				)
				break
			case '/app/refundDetail':
				currentBreadcrumb = rootBreadcrumb.concat(
					refundMainList.concat(refundList.concat(refundDetail))
				)
				break
			case '/app/auditMainRefund':
				currentBreadcrumb = rootBreadcrumb.concat(auditMainRefund)
				break
			case '/app/auditRefund':
				currentBreadcrumb = rootBreadcrumb.concat(
					auditMainRefund.concat(auditRefund)
				)
				break
			case '/app/auditRefundDetail':
				currentBreadcrumb = rootBreadcrumb.concat(
					auditMainRefund.concat(auditRefund.concat(auditRefundDetail))
				)
				break
			case '/app/userManage':
				currentBreadcrumb = rootBreadcrumb.concat(userManage)
				break
			case '/app/userRegister':
				currentBreadcrumb = rootBreadcrumb.concat(
					userManage.concat(userRegister)
				)
				break
			case '/app/remind':
				currentBreadcrumb = rootBreadcrumb.concat(remind)
				break
			case '/app/userInfo':
				currentBreadcrumb = rootBreadcrumb.concat(userSetting)
				break
			case '/app/mainCustomCharging':
				currentBreadcrumb = rootBreadcrumb.concat(mainCustomChargingList)
				break
			case '/app/customCharging':
				currentBreadcrumb = rootBreadcrumb.concat(
					mainCustomChargingList.concat(CustomChargingList)
				)
				break
			case '/app/customChargingDetail':
				currentBreadcrumb = rootBreadcrumb.concat(
					mainCustomChargingList.concat(
						CustomChargingList.concat(CustomChargingDetail)
					)
				)
				break
			case '/app/mainContent':
				currentBreadcrumb = rootBreadcrumb.concat(mainContentList)
				break
			case '/app/content':
				currentBreadcrumb = rootBreadcrumb.concat(
					mainContentList.concat(ContentList)
				)
				break
			case '/app/contentDetail':
			case '/app/contentItem':
				currentBreadcrumb = rootBreadcrumb.concat(
					mainContentList.concat(ContentList.concat(ContentDetail))
				)
				break
			case '/app/roleAdmin':
				currentBreadcrumb = rootBreadcrumb.concat(RoleAdmin)
				break
			case '/app/createRole':
				currentBreadcrumb = rootBreadcrumb.concat(RoleAdmin.concat(CreateRole))
				break
			case '/app/doctorinfo':
				currentBreadcrumb = rootBreadcrumb.concat(DoctorInfo)
				break
			case '/app/configProfile':
				currentBreadcrumb = rootBreadcrumb.concat(
					DoctorInfo.concat(ConfigProfile)
				)
				break
			case '/app/haodf':
				currentBreadcrumb = rootBreadcrumb.concat(DoctorInfo.concat(GoodDoctor))
				break
			case '/app/fans/cashout':
				currentBreadcrumb = rootBreadcrumb.concat(FansCashoutAudit)
				break
			case '/app/fans/cashout/detail':
				currentBreadcrumb = rootBreadcrumb.concat(FansCashoutAudit.concat(
					FansCashoutAuditDetail
				))
				break
			case '/app/doctor/qr-code':
				currentBreadcrumb = rootBreadcrumb.concat(DoctorQRCode)
				break
			case '/app/doctor/settle/list':
				currentBreadcrumb = rootBreadcrumb.concat(DoctorSettleList)
				break
			case '/app/doctor/settle/detail':
				currentBreadcrumb = rootBreadcrumb.concat(DoctorSettleList.concat(DoctorSettleDetail))
				break
			case '/app/doctor/settle/waiting/list':
				currentBreadcrumb = rootBreadcrumb.concat(DoctorSettleList).concat(WaitingDoctorSettleList)
				break
			case '/app/doctor/case/deposit/list':
				currentBreadcrumb = rootBreadcrumb.concat(DoctorCaseDepositList)
				break
			case `${DOCTOR_CASE_DEPOSIT_DETAIL_PATH}`:
				currentBreadcrumb = rootBreadcrumb.concat(DoctorCaseDepositList.concat(DoctorCaseDepositDetail))
				break
			default:
				currentBreadcrumb = rootBreadcrumb
				break
		}
		return currentBreadcrumb
	}

	private getCurrentMenu = () => {
		const { pathname } = this.props.location
		const pathSnippets = pathname.split('/').filter((i: any) => i)
		const startIndex = process.env.NODE_ENV === 'development' ? 0 : 1
		const curMenu = `/${pathSnippets
			.slice(startIndex, pathSnippets.length)
			.join('/')}`

		return curMenu
	}

	public render() {
		const currentBreadcrumb = this.getCurrentBreadcrumb()
		const curMenu = this.getCurrentMenu()

		return (
			<Breadcrumb>
				{currentBreadcrumb &&
					currentBreadcrumb.map((item: any, index: any) => {
						return (
							<BreadcrumbItem key={index}>
								{!item.href || item.href === curMenu ? (
									`${item.name}`
								) : (
									<Link to={updateRoutePath(item.href)}>{item.name}</Link>
								)}
							</BreadcrumbItem>
						)
					})}
			</Breadcrumb>
		)
	}
}

export default BreadcrumbContainer
