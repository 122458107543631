import styled from 'styled-components'
// import * as colors from '../../common/styles/variable'
import LoginBg from '../../assets/images/login_bg.png'
import LogoBg from '../../assets/images/login_box.png'
import BtmText from '../../assets/images/login_text.png'
import { Form, Button } from 'antd'
export const LoginWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	background: url('${LoginBg}');
	background-size: 100% auto;
	box-sizing: border-box;
	padding-top: 187px;
	.desc {
		text-align: center;
		margin-bottom: 110px;
		font-size:30px;
		color:#fff;
		font-weight: 600;
	}
	.bottom-text{
		width: 475px;
		height: 40px;
		margin: 100px auto;
		background: url(${BtmText});
		background-size:100%;
	}
`
export const FormWrapper = styled(Form)`
	&.login-form {
		margin:0 auto;
		width: 480px;
		background: #fff;
		box-sizing: border-box;
		padding:  80px 62px 40px 62px;
		border-radius: 6px;
		position: relative;
		.logo {
			width:224px;
			height:84px;
			color:#fff;
			background:url('${LogoBg}');
			background-size:100%;
			text-align:center;
			line-height: 1;
			font-size: 20px;
			font-weight: 600;
			box-sizing: border-box;
			padding: 28px 0 36px 0;
			position: absolute;
			top: -28px;
			left: 50%;
			transform: translateX(-50%);
			span{
				display:inline-block;
				line-height:1.2;
			}
			img {
				width:35px;
				height:20px;
				margin-right:11px;
				vertical-align:middle;
				margin-top: -5px;
			}
		}
		.tips{
			font-size:16px;
			color: #999;
			text-align:center;
			letter-spacing:10px;
		}
	}
	
`
export const ButtonWrapper = styled(Button)`
	width: 100%;
	height: 42px;
	font-size: 16px;
	margin-top: 20px;
`
