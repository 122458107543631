import { AxiosPromise } from 'axios'
import {
	IDocQuery,
	IUpdateDocInfoQuery,
	IUpdateGdQuery
} from '../common/interface/apiQuery'
import Service from '../common/service'

class DoctorApi extends Service {
	// 获取医生列表
	public List<T>(params: {
		pageSize: number
		pageNo: number
		docId: any
	}): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/doctor/info/mainlist', params)
	}
	// 获取医生资料
	public Info<T>(params: { docId?: any }): AxiosPromise<T> {
		return this.$http.get(`/yimai-admin/doctor/info/getconfig/${params.docId}`)
	}

	// 修改医生资料
	public Update<T>(params: IUpdateDocInfoQuery): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/doctor/info/setconfig', params)
	}

	/**
	 * 医生资料预览
	 * @param params 
	 */
	public Preview<T>(params: IUpdateDocInfoQuery): AxiosPromise<T> {
		return this.$http.post(`yimai-admin//preview/create/doctorInfo`, params)
	}

	// 获取好大夫信息
	public GdInfo<T>(params: { docId: string }): AxiosPromise<T> {
		return this.$http.get(`/yimai-admin/doctor/info/hdfinfo/${params.docId}`)
	}
	// 创建修改好大夫信息
	public UpdateGdInfo<T>(params: IUpdateGdQuery): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/doctor/info/hdfcreate', params)
	}

	/**
	 * 好大夫信息预览
	 * @param params 
	 */
	public HaodfPreview<T>(params: IUpdateGdQuery): AxiosPromise<T> {
		return this.$http.post(`yimai-admin/preview/create/HDFActInfo`, params)
	}
}

export default new DoctorApi()
