import { Icon, Layout, Menu } from 'antd'
import { autobind } from 'core-decorators'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
    randomString,
    updateRoutePath
} from '../../../common/utils/publicUtils'
import { LogoBox } from './style'
import { SideWrapper } from './style'
import logo from '../../../assets/images/ym_logo.png'
require('./style.css')

const { SubMenu } = Menu
interface SideState {
    collapsed: boolean
    selectedKeys: string[]
    openKeys: any[]
}
interface ISideProps {
    menuData: any[]
    currentKey: string
    currentOpenKeys?: string[] | []
    menuOpenHandle: any
    menuItemClickHandle?: () => void
    global?: any
}

interface Item {
    item: object
    key: string
    keyPath: string[]
}

@inject('global')
@autobind
@observer
class SideBar extends Component<ISideProps, SideState> {
    constructor(props: ISideProps, state: SideState) {
        super(props, state)
        this.state = {
            collapsed: false,
            selectedKeys: [],
            openKeys: []
        }
    }

    public onCollapseHandle(collapsed: any, type: any) {
        this.setState({
            collapsed
        }, () => {
            this.props.global.siderToggle(collapsed)
        })
    }
    public handleMenuItemClick(state: Item) {
        // this.props.menuItemClickHandle()
    }
    public menuOpenHandle = (keys: string[]) => {
        this.setState({
            openKeys: keys
        })
    }

    public render() {
        const { menuData } = this.props
        console.log(menuData)
        return (
            <SideWrapper
                width={200}
                theme='dark'
                collapsible
                collapsed={this.state.collapsed}
                onCollapse={this.onCollapseHandle}>
                <LogoBox >
                    <img className="logo" style={{ height: '100%' }} src={logo} />
                </LogoBox>
                <Menu
                    mode='inline'
                    defaultSelectedKeys={[]}
                    defaultOpenKeys={[]}
                    selectedKeys={[this.props.currentKey]}
                    openKeys={this.props.currentOpenKeys}
                    theme='dark'
                    onOpenChange={this.props.menuOpenHandle}
                    onClick={this.handleMenuItemClick}>
                    {menuData.map(
                        (submenu, index) =>
                            submenu.children && (
                                <SubMenu
                                    key={index.toString()}
                                    title={
                                        <span>
                                            <Icon type={submenu.icon} />
                                            <span>{submenu.label}</span>
                                        </span>
                                    }>
                                    {submenu.children.map(
                                        (menuItem: any, i: number) => (
                                            <Menu.Item key={menuItem.path}>
                                                <Link
                                                    to={updateRoutePath(
                                                        menuItem.path.trim()
                                                    )}>
                                                    {menuItem.label}
                                                </Link>
                                            </Menu.Item>
                                        )
                                    )}
                                </SubMenu>
                            )
                    )}
                </Menu>
            </SideWrapper>
        )
    }
}

export default SideBar
