import { action, computed, observable, runInAction } from 'mobx'
import DoctorCashDepositApi, {
  IDoctorCashDepositBaseQuery,
  IDoctorCashDepositQuery,
  IDoctorCashDepositRecordsQuery,
  IDoctorCashDepositDetail
} from '../apis/doctorCashDeposit'
import { handleList } from '../common/utils/handler'

class DoctorCashDepositStore {
  @observable public list: any = {}
  @observable public detail: any = {}
  @observable public records: any = {}

  @action public async getList(params: IDoctorCashDepositQuery) {
    const { data } = await DoctorCashDepositApi.list(params)

    runInAction(() => {
      const { list, startRow } = data as any
      const nextList = handleList(list, startRow)

      this.list = {
        ...{},
        ...data as any,
        ...{
          list: nextList
        }
      }
    })

    return data
  }

  @action public async getDetail(id: string | number) {
    const { data } = await DoctorCashDepositApi.getDetail(id)

    runInAction(() => {
      this.detail = data
    })

    // return data
  }

  @action public updateDetail(payload: IDoctorCashDepositDetail) {
    const { detail } = this

    runInAction(() => {
      this.detail = {
        ...{},
        ...detail,
        ...payload
      }
    })
  }

  @action public async getRecordsList(params: IDoctorCashDepositRecordsQuery) {
    const { data } = await DoctorCashDepositApi.getRecords(params)

    runInAction(() => {
      this.records = data
    })

    return data
  }

  @action public async onDeposit(params: any) {
    const data = await DoctorCashDepositApi.onDeposit(params)

    return data
  }

  @action public async onAdjust(params: any) {
    const data = await DoctorCashDepositApi.onAdjust(params)

    return data
  }

  @action public async onAudit(params: any) {
    const data = await DoctorCashDepositApi.onAudit(params)

    return data
  }

  @action public async onAbandon(params: any) {
    const data = await DoctorCashDepositApi.onAbandon(params)

    return data
  }

  @action public async onSetLimitFee(params: any) {
    const data = await DoctorCashDepositApi.onSetLimitFee(params)

    return data
  }

}

export default DoctorCashDepositStore