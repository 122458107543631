import { message } from 'antd'
import { action, computed, observable, runInAction } from 'mobx'
import DoctorApi from '../apis/doctor'
import { history } from '../containers/Root/Root'
interface IInfo<T> {
	avatar: string
	docId: string
	grade: string
	gradecertificate: string
	honors: string
	hospital: string
	license: string
	name: string
	personalStyle: string
	profile: string
	projects: string
	qualitycertificate: string
	signature: string
	titles: string
	welcomes: string
}
interface Item {
	[key: string]: any
}

class ConfigDoctor {
	@observable public docId: string = ''
	@observable public docInfo: IInfo<Item> = {
		avatar: '',
		docId: '',
		grade: '',
		gradecertificate: '',
		honors: '',
		hospital: '',
		license: '',
		name: '',
		personalStyle: '',
		profile: '',
		projects: '',
		qualitycertificate: '',
		signature: '',
		titles: '',
		welcomes: ''
	}
	@observable public projects: string[] = ['', '', '', '']
	@observable public previewInfo: any = {}

	@computed public get isDisabled(): boolean {
		const fieldArr = [
			'name',
			'grade',
			'profile',
			'qualitycertificate',
			'license',
			'gradecertificate',
			'hospital'
		]
		const required = fieldArr.some(
			field => (this.docInfo as Item)[field] === ''
		)
		const projectFilter = this.projects.filter(c => c !== '')
		return required || !projectFilter.length
	}

	@action public updateDocid = (docId: string) => {
		this.docId = docId
	}
	@action public getInfo = async (docId: string) => {
		const { data } = await DoctorApi.Info({ docId })
		const projects = data.projects.split('|')
		Object.keys(data).forEach(el => {
			data[el] = !data[el] ? '' : data[el]
		})
		this.docInfo = Object.assign({}, this.docInfo, data)

		for (let i = 0; i < 4; i++) {
			const el = projects[i]
			if (!el) {
				projects.push('')
			}
		}
		this.projects = projects
	}

	@action public changeInfo = (field: string, value: string) => {
		;(this.docInfo as Item)[field] = value
	}
	@action public changeProject = (index: number, value: string) => {
		this.projects[index] = value
	}

	@action public updateInfo = async () => {
		try {
			this.docInfo.projects = this.projects.filter(c => c !== '').join('|')
			await DoctorApi.Update(this.docInfo)
			message.success('修改成功')
			history.goBack()
		} catch (error) {
			console.log(error)
			message.error(error.message)
		}
	}

	@action public getPreviewInfo = async () => {
		this.docInfo.projects = this.projects.filter(c => c !== '').join('|')
		const { data } = await DoctorApi.Preview(this.docInfo)
		runInAction(() => {
			this.previewInfo = data
		})
		return data
	}

	@action public freed = () => {
		const keys = Object.keys(this.docInfo)
		for (const key in keys) {
			if (this.docInfo.hasOwnProperty(keys[key])) {
				;(this.docInfo as Item)[keys[key]] = ''
			}
		}
	}
}

export default ConfigDoctor
