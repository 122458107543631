import { action, computed, observable, runInAction } from 'mobx'
import DoctorSettleApi, {
  IDoctorSettleBaseQuery,
  IDoctorSettleDetail, IDoctorSettleDetailQuery, IDoctorSettleListQuery,
  ISetDoctorIncome,
  IDoctorSettleQuery,
  ISettingListQuery,
  IUpdateReportEntity,
  IComputeIncomeEntity,
  IWaitingDoctorSettleQuery
} from '../apis/doctorSettle'
import OrderApi from '../apis/Order'
import { IDocQuery } from '../common/interface/apiQuery'
import { pruneEmpty, randomString } from '../common/utils/publicUtils'
import { handleList } from '../common/utils/handler'
import { replace } from 'lodash'
import { RefundSettleType } from '../common/enums'

class DoctorSettleStore {
  @observable public list: any = {}
  @observable public detail: any = {}
  @observable public settlementList: any = {}
  @observable public settlementTypeList: any = {
    currMonth: {},
    currMonthRefund: {},
    historyRefund: {},
    unComfirmRefund: {}
  }
  @observable public settingData: any = {}
  @observable public doctorList: any[] = []
  @observable public settingDetail: any = {}
  @observable public waitingDoctorSettleList: any = {
    List: [],
    total: 0
  }

  @action public async getList(params: IDoctorSettleQuery) {
    const { data } = await DoctorSettleApi.list(params)

    runInAction(() => {
      const { list, startRow } = data as any
      const nextList = handleList(list, startRow)

      this.list = {
        ...{},
        ...(data as any),
        ...{
          list: nextList
        }
      }
    })

    return data
  }

  @action public async onBuild(params: IDoctorSettleBaseQuery) {
    const data = await DoctorSettleApi.build(params)

    return data
  }

  @action public async onBuildAll(params: IDoctorSettleBaseQuery) {
    const data = await DoctorSettleApi.buildAll(params)

    return data
  }

  @action
  public async onInitAll(params: IDoctorSettleBaseQuery) {
    const data = await DoctorSettleApi.initAll(params)

    return Promise.resolve(data)
  }

  @action public async getDetail(params: IDoctorSettleDetailQuery) {
    const data = await DoctorSettleApi.detail(params)

    runInAction(() => {
      const { data: detail } = data as any
      let { otherCost } = detail
      this.detail = {
        ...{}, ...detail, ...{
          otherCost: otherCost || 0
        }
      }
    })

    return data
  }

  @action public async getSettleList(params: IDoctorSettleListQuery) {
    const res = await DoctorSettleApi.settlementList(params)

    runInAction(() => {
      const { data } = res as any
      this.settlementList = { ...{}, ...data }

      const { list } = data

      let currMonth = list.filter((item: any) => item.refundSettleType == RefundSettleType.currMonth ||
        item.refundSettleType == RefundSettleType.currMonthRefund
      )
      let currMonthRefund = list.filter((item: any) => item.refundSettleType == RefundSettleType.currMonthRefund)
      let historyRefund = list.filter((item: any) => item.refundSettleType == RefundSettleType.historyRefund)
      let unComfirmRefund = list.filter((item: any) => item.refundSettleType == RefundSettleType.unComfirmRefund)

      this.settlementTypeList = {
        ...{},
        ...{
          currMonth: {
            list: currMonth
          },
          currMonthRefund: {
            list: currMonthRefund
          },
          historyRefund: {
            list: historyRefund
          },
          unComfirmRefund: {
            list: unComfirmRefund
          }
        }
      }
    })

    return res.data
  }

  @action public updateDetail(payload: IDoctorSettleDetail) {
    const { detail } = this

    runInAction(() => {
      this.detail = {
        ...{},
        ...detail,
        ...payload
      }
    })
  }

  @action public async setDoctorIncome(params: ISetDoctorIncome) {
    const data = await DoctorSettleApi.setDoctorIncome(params)

    return data
  }

  @action public async setComputeIncome(params: IComputeIncomeEntity) {
    const data = await DoctorSettleApi.computeIncome(params)

    return data
  }

  @action public async getSettingList(params: ISettingListQuery) {
    const res = await DoctorSettleApi.settingList(params)

    const { data } = res as any
    runInAction(() => {
      this.settingData = { ...{}, ...data }
    })

    return data
  }

  @action public async getSettingDetail(params: any) {
    const res = await DoctorSettleApi.settingDetail(params)

    const { data } = res as any
    runInAction(() => {
      this.settingDetail = { ...{}, ...data }
    })

    return data
  }

  @action public async onUpdateReport(params: IUpdateReportEntity) {
    const data = await DoctorSettleApi.updateReport(params)

    return data
  }

  @action public async searchDoctorList(params: { docName: string }) {
    const res = await OrderApi.SearchDoctor(params)
    const { data } = res as any

    runInAction(() => {
      this.doctorList = data
    })

    return data
  }

  @action public async onComplete(params: any) {
    const data = await DoctorSettleApi.complete(params)

    return data
  }

  @action public async onOrderComplete(params: any) {
    const data = await DoctorSettleApi.orderComplete(params)

    return data
  }

  @action public async onPush(params: any) {
    const data = await DoctorSettleApi.push(params)

    return data
  }

  @action public async onSettleDelayConfirm(params: any) {
    const data = await DoctorSettleApi.settleDelayConfirm(params)

    return data
  }

  @action public async getWaitingDoctorSettleList(params: IWaitingDoctorSettleQuery) {
    const { data } = await DoctorSettleApi.waitingDoctorSettleList(params)

    runInAction(() => {
      const { list, startRow } = data as any
      const nextList = handleList(list, startRow)
      // debugger
      this.waitingDoctorSettleList = {
        ...{},
        ...(data as any),
        ...{
          list: nextList
        }
      }
    })

    return data
  }
}

export default DoctorSettleStore