import { autobind } from 'core-decorators'
import { createBrowserHistory } from 'history'
import { inject, observer } from 'mobx-react'
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import React, { Component, Fragment } from 'react'
import { Route, Router, Switch } from 'react-router'
import { updateRoutePath } from '../../common/utils/publicUtils'
import App from '../App'
import Login from '../Login'

const browserHistory = createBrowserHistory()
const routerStore = new RouterStore()
export const history = syncHistoryWithStore(browserHistory, routerStore)
@inject('router', 'auth', 'header')
@autobind
@observer
export default class Root extends Component {
	public render() {
		return (
			<Router history={history}>
				<Switch>
					<Route exact={true} path={updateRoutePath('/')} component={Login} />
					<Route path={updateRoutePath('/app')} component={App} />
				</Switch>
			</Router>
		)
	}
}
