import { AxiosPromise } from 'axios'
import Config from '../common/config'
import { IDocQuery, ListBase } from '../common/interface/apiQuery'
import Service from '../common/service'
import { hasMock } from '../common/utils/publicUtils'
const { mockApi } = Config.app
const mockApiPrefix = `${mockApi}/content`

export interface IContentQuery extends ListBase {
	docId: string | number
	type?: string | number
	projectName?: string
	tag?: string | number
	category?: string | number
	orderBy?: string
}

export interface IContentDetail {
	id: string | number
	docId: string | number
	type: string | number
	publisherType: string | number
	fansNickname: string | number
	headimgurl: string | number
	projectName: string
	operationDate: string
	projectAmount: string | number
	scope: string | number
	scopeFans: any
	tags: any[]
	beforePics: any[]
	beforeDescription: string
	caseItemForm: IContentPhase[]
	name?: string
}

export interface IContentPhase {
	id: string | number
	caseId: string | number
	postDescription: string
	postPics: any[]
	after: string | number
	unit: string | number
	views?: string | number
	likeCount?: string | number
	replyCount?: string | number
	step?: string | number
	days?: number
	viewCount?: string | number
	author?: string
	createTime?: number
	projectName?: string
	scope?: string | number
}

class ContentApi extends Service {
	/**
	 *
	 * @param params
	 */
	public docList<T>(params: IDocQuery): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApiPrefix}/mainlist.json`)
		} else {
			return this.$http.post('yimai-admin/doctor/cases/mainlist', params)
		}
	}

	/**
	 *
	 * @param params
	 */
	public list<T>(params: IContentQuery): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApiPrefix}/list.json`)
		} else {
			return this.$http.post('yimai-admin/doctor/casesaffiche/list', params)
		}
	}

	/**
	 * @param params
	 */
	public create<T>(params: IContentDetail): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApi}/success.json`)
		} else {
			return this.$http.post(`yimai-admin/doctor/cases/pushcase`, params)
		}
	}

	/**
	 *
	 * @param params
	 */
	public detail<T>(params: any): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApiPrefix}/detail.json`)
		} else {
			return this.$http.post('yimai-admin/doctor/cases/casedetail', params)
		}
	}

	/**
	 *
	 * @param params
	 */
	public item<T>(params: any): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApiPrefix}/item.json`)
		} else {
			return this.$http.get(`yimai-admin/doctor/cases/findItem/${params.id}`)
		}
	}

	/**
	 *
	 * @param params
	 */
	public top<T>(params: any): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApi}/success.json`)
		} else {
			return this.$http.post('yimai-admin/doctor/cases/top', params)
		}
	}

	/**
	 *
	 * @param params
	 */
	public delete<T>(params: any): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApi}/success.json`)
		} else {
			return this.$http.post('yimai-admin/doctor/cases/deletecase', params)
		}
	}

	/**
	 *
	 * @param params
	 */
	public setVisible<T>(params: any): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApi}/success.json`)
		} else {
			return this.$http.post('yimai-admin/doctor/cases/setvisible', params)
		}
	}

	/**
	 *
	 * @param params
	 */
	public tagList<T>(params: {
		docId: string | number
		type: string | number
	}): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApiPrefix}/tags.json`)
		} else {
			return this.$http.post('yimai-admin/doctor/cases/taglist', params)
		}
	}

	/**
	 *
	 * @param params
	 */
	public deletePhase<T>(params: {
		id: string | number
	}): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApi}/success.json`)
		} else {
			return this.$http.post(`/yimai-admin/doctor/cases/deleteItem/${params.id}`)
		}
	}

	/**
	 * @param params
	 */
	public preview<T>(params: IContentDetail): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApiPrefix}/preview.json`)
		} else {
			return this.$http.post(`yimai-admin/preview/create/getCaseInfo`, params)
		}
	}

	/**
	 * @param params
	 */
	public phasePreview<T>(params: IContentDetail): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApiPrefix}/preview.json`)
		} else {
			return this.$http.post(`yimai-admin/preview/create/getCaseItemInfo`, params)
		}
	}

	/**
	 * 
	 * @param params 
	 */
	public createPhase<T>(params: any): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApi}/success.json`)
		} else {
			return this.$http.post(`yimai-admin/doctor/cases/mergeItem`, params)
		}
	}

	/**
	 * 
	 * @param params 
	 */
	public getPhase<T>(params: any): AxiosPromise<T> {
		return this.$http.get(`yimai-admin/doctor/cases/findItem/${params.itemId}`)
	}

	/**
	 * 
	 * @param params 
	 */
	public getDoctorTagList<T>(params: any): AxiosPromise<T> {
		return this.$http.post(`yimai-admin/doctor/cases/doctortags`, params)
	}

	/**
	 * 
	 * @param params 
	 */
	public addDoctorTag<T>(params: any): AxiosPromise<T> {
		return this.$http.post(`/yimai-admin/doctor/cases/addtag`, params)
	}

	/**
	 * 
	 * @param params 
	 */
	public getShare<T>(params: any): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApiPrefix}/previewShare.json`)
		} else {
			return this.$http.get(`yimai-admin/preview/create/getPosterUrl/${params.caseId}`)
		}
	}
}

export default new ContentApi()
