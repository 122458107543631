import { AxiosPromise } from 'axios'
import { ListBase, IDocQuery } from '../common/interface/apiQuery'
import Service from '../common/service'
import { hasMock } from '../common/utils/publicUtils'
import Config from '../common/config'
const { mockApi } = Config.app
const mockApiPrefix = `${mockApi}/fans`

export interface IFansQuery extends ListBase {
	docId: string | number
	name?: string | number
	tagId?: string | number
}

export interface IFansDetail {
	fansId: string | number
	fansNo: string
	noteName: string
	fansName: string 
	headimgurl: string
	tags: any[]
	nickName: string
	source: string | number
	phone: string | number
}

class FansApi extends Service {
	/**
	 * 
	 * @param params 
	 */
	public list<T>(params: IFansQuery): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApiPrefix}/list.json`)
		} else {
			return this.$http.post('yimai-admin/doctor/fans/fansList', params)
		}
	}

	public tags<T>(params: {docId: string | number}): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApiPrefix}/tags.json`)
		} else {
			return this.$http.get(`yimai-admin/doctor/fans/fanstags/${params.docId}`)
		}
	}
}

export default new FansApi()