/**
 * 退款状态
 */
export enum RefundStatus {
  waitAudit = 10, //待财务审核
  waitDoctorAudit = 20, //待医生审核
  refunding = 30, //待退款
  refunded = 40, //已退款
  canceled = 50, //已撤销
  rejected = 60, //被财务驳回
  doctorRejected = 70, //被医生驳回
  refundFail = 80, //退款失败
  closed = 90, //已关闭
  manualRefund = 100
}

/**
 * 订单类型
 */
export enum OrderType {
  ORDER_TYPE_DIAGNOSIS = 1, // 面诊
  ORDER_TYPE_SURGERY = 2, // 手术
  ORDER_TYPE_CUSTOM = 3 // 自定义项目
}

/**
 * 支付方式
 */
export enum PayChannelType {
  PAY_CHANNEL_WXPAY = 1, // 微信
  PAY_CHANNEL_ALIPAY = 2, // 支付宝
  PAY_CHANNEL_LIANLIANPAY = 3, // 连连支付
  PAY_CHANNEL_OFFLINE = 4 // 线下支付
}

/**
 * 粉丝类型
 */
export enum FansSource {
  FANS_SOURCE_UNDEFINED = 0, // 未定义
  FANS_SOURCE_CLINIC = 1, // 门诊
  FANS_SOURCE_CONSULT = 2 // 口碑
}

/**
 * 退款类型
 */
export enum RefundType {
  ALL = 0, //全额退款
  PARTIAL = 1 //部分退款
}

/**
 * 
 */
export enum WithdrawDetail {
  WAIT_AUDIT = 0, //待审核
  ACCEPT = 1, //审核通过
  TIMEOUT = 2, //超时
  SUCCESS = 3, //已打款
  FAIL = 99 //审核失败
}

/**
 * 内容类型
 */
export enum ContentType {
  CASE = 1, //案例
  BULLETIN = 2, //公告
}

/**
 * 内容发布身份
 */
export enum ContentPublisherType {
  DOC = 2, //医生
  FANS = 1, //粉丝
}

/**
 * 内容可见范围
 */
export enum ContentScope {
  ALL = 1, //公开
  WHITE_LIST = 2, //部分可见
  BLACK_LIST = 3 //部队不可见
}

/**
 * 置顶
 */
export enum Rised {
  TOP = 1, //置顶
  NORMAL = 2 //普通
}

/**
 * 申请退款类型
 */
export enum ApplyRefundType {
  ALL_AT_RULE = '1', //按平台规则退款100%
  NINE_PERCENT_AT_RULE = '2', //按平台规则退款90
  REDUCE = '3', //手术总金额同比下降
  ENTIRE = '4', //手术总金额全额退款
  BOOKING_REDUCE = '5', //手术总金额不变，预约服务费下降
  REDUCE_BOOKING_FIXED = '6' //手术总金额下降，预约服务费不变
}

/**
 * 
 */
export enum UploadMediaType {
  IMAGE = 1, //照片
  VIDEO = 2 //视频
}

/**
 * 
 */
export enum ContentFilterType {
  ALL = 1, //全部
  CASE = 2, //案例
  BULLETIN = 3 //公告
}

/**
 * 
 */
export enum ReplyVisibleType {
  visible = '0', //可见
  unVisible = '1' //不可见
}

/**
 * 用户类型
 */
export enum UserType {
  fans = 0, // 粉丝
  doctor = 1, // 医生
  assistant = 2, // 助手
  stewardship = 3 // 管家
}

/**
 * 补单状态
 */
export enum ReplenishmentsStatus {
  waitAudit = '10', // 待审核
  accept = '20', // 审核通过
  reject = '30' // 驳回
}

/**
 * 退款方式
 */
export enum RefundMode {
  all = '1', //全部
  part = '2' //部分
}

/**
 * 退款结算类型
 */
export enum RefundSettleType {
  currMonth = 0,
  currMonthRefund = 1,
  historyRefund = 2,
  unComfirmRefund = 3
}

/**
 * 结算计算方式
 */
export enum settleCalcWay {
  normal = 1,
  spacial = 2,
  mutilHospital = 3
}

/**
 * 结算状态
 */
export enum settleStatus {
  waitSettle = 1,
  waitAudit = 2,
  audited = 3,
  done = 4,
  waitDoctorSettle = 5
}

/**
 * 结算列表状态
 */
export enum settleListStatus {
  waitBuild = 1, // 待生成
  waitComfirm = 2, // 待确认
  waitPush = 3, // 待推送
  done = 4 // 已完成
}

/**
 * ON and OFF
 */
export enum onAndOff {
  ON = 'ON',
  OFF = 'OFF'
}

/**
 * 结算时间类型
 */
export enum enumSettleTimeType {
  ByCreatedAt = '1',
  ByCompletedAt = '2'
}

/**
 * 结算周期类型
 */
export enum enumSettlePeriodType {
  day = '1',
  month = '2'
}

export enum settleApplyFormula {
  One = '1',
  Two = '2',
  Three = '3'
}

export enum doctorCashDepositRecordsStatus {
  Init = '1',
  Valid = '2',
  InValid = '3'
}

export enum doctorCashDepositRecordsOperateMode {
  Deposit = 'Deposit',
  Adjust = 'Adjust'
}

export enum doctorCashDepositRecordsType {
  Income = '1',
  Expense = '2'
}

export enum doctorCashDepositRecordsSubType {
  Adjust = '31'
}