import { action, observable, runInAction } from 'mobx'
import UserApi from '../apis/User'

class GlobalModel {
	@observable public userInfo = {}
	@observable public Menus: any[] = []
	@observable public siderCollapse: boolean = false
	@observable public currentPageDoctor: string = ''

	@action public async getUserInfo() {
		const { data: res } = await UserApi.GetUserInfo()
		runInAction(() => {
			this.userInfo = res
		})
		return res
	}
	@action public async getMenuData() {
		const { data: res } = await UserApi.GetUserResources()
		this.Menus = res
	}

	@action public async siderToggle(toggle: boolean) {
		this.siderCollapse = toggle
		runInAction(() => { })
	}
	@action public async loginOut() {
		try {
			await UserApi.LoginOut()
			window.sessionStorage.removeItem('token')
			window.sessionStorage.removeItem('userInfo')
			return Promise.resolve('success')
		} catch (error) {
			return Promise.reject(error)
		}
	}
	@action public getCurPageDoc(docName: string) {
		this.currentPageDoctor = docName
	}
	constructor() {
		this.userInfo = {}
		this.siderCollapse = false
	}
}

export default GlobalModel
