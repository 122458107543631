import React from 'react'
import Loadable from 'react-loadable'
import { Route, Router, Switch } from 'react-router'
import { updateRoutePath } from '../common/utils/publicUtils'
import Loading from './Loading'
import {
	DOCTOR_CASE_DEPOSIT_DETAIL_PATH
} from '../common/constants/path'
const MenuRoutes: any[] = [
	{
		path: '/app/orderDetail',
		component: () => import('../containers/Order/OrderDetail')
	},
	{
		path: '/app/order',
		component: () => import('../containers/Order')
	},
	{
		path: '/app/userOrder',
		component: () => import('../containers/Order/UserOrder/index_v2')
	},
	{
		path: '/app/makeup',
		component: () => import('../containers/MakeUpOrder')
	},
	{
		path: '/app/makeupRecord',
		component: () => import('../containers/MakeUpOrder/MakeUpRecord')
	},
	{
		path: '/app/createMakeup',
		component: () => import('../containers/MakeUpOrder/CreateMakeUp')
	},
	{
		path: '/app/makeupReview',
		component: () => import('../containers/MakeUpOrder/MakeUpDetail')
	},
	{
		path: '/app/financeReview',
		component: () => import('../containers/FinanceReview')
	},
	{
		path: '/app/withdrawReview',
		component: () => import('../containers/WithDrawReview')
	},
	{
		path: '/app/reviewDetail',
		component: () => import('../containers/WithDrawReview/ReviewDetail')
	},
	{
		path: '/app/configInfo/:tab',
		component: () => import('../containers/ConifgInfo/index_v2')
	},
	{
		path: '/app/userRegister',
		component: () => import('../containers/Permission/Register')
	},
	{
		path: '/app/userManage',
		component: () => import('../containers/Permission/UserManage')
	},
	{
		path: '/app/remind',
		component: () => import('../containers/Permission/Remind')
	},
	{
		path: '/app/remindRecord',
		component: () => import('../containers/Permission/RemindRecord')
	},
	{
		path: '/app/createEvent',
		component: () => import('../containers/Permission/CreateEvent')
	},
	{
		path: '/app/orderSettle',
		component: () => import('../containers/OrderSettle')
	},
	{
		path: '/app/docOrderSettle',
		component: () => import('../containers/OrderSettle/DocOrderSettle/index_v2')
	},
	{
		path: '/app/makeupReviewDetail',
		component: () => import('../containers/FinanceReview/BatchReview')
	},
	{
		path: '/app/userAllMakeup',
		component: () => import('../containers/FinanceReview/UserAllMakeup/index_v2')
	},
	{
		path: '/app/withdrawSettle',
		component: () => import('../containers/WithdrawSettle')
	},
	{
		path: '/app/settingDetail',
		component: () => import('../containers/ConifgInfo/SettingDetail/index_v2')
	},
	{
		path: '/app/refund',
		component: () => import('../containers/Refund/List')
	},
	{
		path: '/app/refundDetail',
		component: () => import('../containers/Refund/Detail')
	},
	{
		path: '/app/auditRefund',
		component: () => import('../containers/AuditRefund/List')
	},
	{
		path: '/app/auditRefundDetail',
		component: () => import('../containers/AuditRefund/Detail')
	},
	{
		path: '/app/auditMainRefund',
		component: () => import('../containers/AuditRefund/MainList')
	},
	{
		path: '/app/mainRefund',
		component: () => import('../containers/Refund/MainList')
	},
	{
		path: '/app/settleDetail',
		component: () => import('../containers/WithdrawSettle/SettleDetail')
	},
	{
		path: '/app/userInfo',
		component: () => import('../containers/PersonalInfo')
	},
	{
		path: '/app/orderSettleDetail',
		component: () => import('../containers/OrderSettle/Detail')
	},
	{
		path: '/app/demo',
		component: () => import('../containers/Demo')
	},
	{
		path: '/app/mainCustomCharging',
		component: () => import('../containers/CustomCharging/MainList')
	},
	{
		path: '/app/customCharging',
		component: () => import('../containers/CustomCharging/List')
	},
	{
		path: '/app/customChargingDetail',
		component: () => import('../containers/CustomCharging/Detail')
	},
	{
		path: '/app/mainContent',
		component: () => import('../containers/Content/MainList')
	},
	{
		path: '/app/content',
		component: () => import('../containers/Content/List')
	},
	{
		path: '/app/contentDetail',
		component: () => import('../containers/Content/Detail')
	},
	{
		path: '/app/contentItem',
		component: () => import('../containers/Content/Item')
	},
	{
		path: '/app/roleAdmin',
		component: () =>
			import(
				/* webpackChunkName:'RoleAdmin'*/ '../containers/Permission/RoleAdmin/RoleAdmin'
			)
	},
	{
		path: '/app/createRole',
		component: () =>
			import(
				/* webpackChunkName:'createRole'*/ '../containers/Permission/CreateRole/CreateRole'
			)
	},
	{
		path: '/app/doctorinfo',
		component: () =>
			import(
				/* webpackChunkName:'doctorinfo'*/ '../containers/DoctorInfo/DoctorInfo'
			)
	},
	{
		path: '/app/configProfile',
		component: () =>
			import(
				/* webpackChunkName:'configProfile'*/ '../containers/DoctorInfo/ConfigProfile'
			)
	},
	{
		path: '/app/haodf',
		component: () =>
			import(
				/* webpackChunkName:'goodDoctor'*/ '../containers/DoctorInfo/GoodDoctor'
			)
	},
	{
		path: '/app/fans/cashout',
		component: () =>
			import(/* webpackChunkName:'cashoutAudit'*/ '../containers/fans/CashOut/Audit')
	},
	{
		path: '/app/fans/cashout/detail',
		component: () =>
			import(/* webpackChunkName:'cashoutAuditDetail'*/ '../containers/fans/CashOut/Audit/detail')
	},
	{
		path: '/app/announce/modify',
		component: () =>
			import(/* webpackChunkName:'contentAnnounceModify' */ '../containers/Content/Announce/modify')
	},
	{
		path: '/app/doctor/qr-code',
		component: () =>
			import(/* webpackChunkName:'SystemSettings' */ '../containers/DoctorQRCode')
	},
	{
		path: '/app/doctor/settle/list',
		component: () =>
			import(/* webpackChunkName:'DoctorSettle' */ '../containers/doctor/settlement/list')
	},
	{
		path: '/app/doctor/settle/detail',
		component: () =>
			import(/* webpackChunkName:'DoctorSettle' */ '../containers/doctor/settlement/detail')
	},
	{
		path: '/app/doctor/settle/waiting/list',
		component: () =>
			import(/* webpackChunkName:'DoctorSettle' */ '../containers/doctor/settlement/waiting_doctor_list')
	},
	{
		path: '/app/doctor/case/deposit/list',
		component: () => 
			import(/* webpackChunkName:'DoctorCaseDeposit' */ '../containers/doctor/cashDeposit/list')
	},
	{
		path: `${DOCTOR_CASE_DEPOSIT_DETAIL_PATH}`,
		component: () => 
			import(/* webpackChunkName:'DoctorCaseDeposit' */ '../containers/doctor/cashDeposit/detail')
	}
]

const RootRouterMap = () => (
	<Switch>
		{MenuRoutes.map(c => (
			<Route
				key={c.path}
				exact={true}
				path={updateRoutePath(c.path)}
				component={Loadable({
					loader: c.component,
					loading: Loading
				})}
			/>
		))}
	</Switch>
)

export default RootRouterMap