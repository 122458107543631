import { autobind } from 'core-decorators'
import { inject, observer } from 'mobx-react'
import React, { Component, Fragment } from 'react'
import { Form, Row, Col, Input, Button, Select, Upload, Icon, message, Divider, Typography } from 'antd'
import moment from 'moment'
import { IHeader, IAuth } from '../../../common/interface'
import { HeaderWrapper } from './style'
require('./style.css')

interface HeaderProps {
    header: IHeader
    global?: any
}

@inject('global')
@autobind
@observer
class MHeader extends Component<HeaderProps> {
    public render() {
        const { siderCollapse } = this.props.global
        const { enterDays, totalNum } = this.props.header

        return (
            <HeaderWrapper className={siderCollapse ? 'header-panel ant-layout-sider-collapsed': 'header-panel'}>
                <Row type="flex" justify="space-between">
                    {!!enterDays && <Col style={{fontSize: '22px'}}>您与一起美已并肩奋斗{enterDays}天</Col>}
                    {!!totalNum && <Col>医脉平台已入驻医生{totalNum}位</Col>}
                </Row>
            </HeaderWrapper>
        )
    }
}

export default MHeader
