import { AxiosPromise } from 'axios'
import Config from '../common/config'
import { IDocQuery, ListBase } from '../common/interface/apiQuery'
import Service from '../common/service'
import { hasMock } from '../common/utils/publicUtils'
const { mockApi } = Config.app
const mockApiPrefix = `${mockApi}/announce`

export interface IAnnounceDetail {
	id?: string | number
	docId: string | number
	noticeType: string | number
	publisherType: string | number
	content: string
	noticeImages?: any[]
	noticeVideo?: string
	address?: string
	lat?: number
	lng?: number
	publicScope: string | number
	fansList: any[]
	fansNickname?: string
	createTime?: number
	viewCount?: number
	replyCount?: number
	likeCount?: number
}

class AnnounceApi extends Service {
  /**
	 * 创建修改
	 * @param params
	 */
	public create<T>(params: IAnnounceDetail): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApi}/success.json`)
		} else {
			return this.$http.post(`yimai-admin/doctor/affiche/modify`, params)
		}
  }

	/**
	 * 详情
	 * @param params
	 */
	public detail<T>(params: any): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApiPrefix}/detail.json`)
		} else {
			return this.$http.post('yimai-admin/doctor/affiche/detail', params)
		}
	}

	/**
	 * 置顶
	 * @param params 
	 */
	public top<T>(params: any): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(``)
		} else {
			return this.$http.post('yimai-admin/doctor/affiche/top', params)
		}
	}

	/**
	 * 删除
	 * @param params 
	 */
	public delete<T>(params: any): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(``)
		} else {
			return this.$http.post('yimai-admin/doctor/affiche/delete', params)
		}
	}

	/**
	 * 设置可见范围
	 * @param params 
	 */
	public setVisible<T>(params: any): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(``)
		} else {
			return this.$http.post('yimai-admin/doctor/affiche/setVisible', params)
		}
	}

	/**
	 * 生成预览链接
	 * @param params 
	 */
	public getAfficheInfo<T>(params: any): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(``)
		} else {
			return this.$http.post('yimai-admin/preview/create/getAfficheInfo', params)
		}
	}

	public hide<T>(params: any): AxiosPromise<T> {
		return this.$http.post(`yimai-admin/affiche/reply/hide`, params)
	}
	
}

export default new AnnounceApi()