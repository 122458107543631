import { AxiosPromise } from 'axios'
import Config from '../common/config'
import { ListBase } from '../common/interface/apiQuery'
import Service from '../common/service'
import { hasMock } from '../common/utils/publicUtils'
const { mockApi } = Config.app
const mockApiPrefix = `${mockApi}/doctorCashDeposit`

export interface IDoctorCashDepositBaseQuery {
  docId?: string | number
  status?: string | number
}

export interface IDoctorCashDepositQuery extends IDoctorCashDepositBaseQuery, ListBase {

}

export interface IDoctorCashDepositRecordsBaseQuery {
  docId?: string | number
  doctorCashDepositId?: string | number
  beginTime?: string | number
  endTime?: string | number
  status: string | number
}

export interface IDoctorCashDepositRecordsQuery extends IDoctorCashDepositRecordsBaseQuery, ListBase { }

export interface IDoctorCashDepositDetail {
  id?: string | number
  docId?: string | number
  balance?: string | number
  status?: string | number
  statusDesc?: string | number
  createTime?: string 
  limitFee?: string | number
  setLimitTime?: string 
  setLimitor?: string 
}

class DoctorCashDepositApi extends Service {

  public list<T>(params: IDoctorCashDepositQuery): AxiosPromise<T> {
    return this.$http.post('yimai-admin/doctor/cash/deposit/list', params)
  }

  public getDetail<T>(id: string | number): AxiosPromise<T> {
    return this.$http.get(`yimai-admin/doctor/cash/deposit/detail/${id}`)
  }

  public getRecords<T>(params: IDoctorCashDepositRecordsQuery): AxiosPromise<T> {
    return this.$http.post('/yimai-admin/doctor/cash/deposit/records/list', params)
  }

  public onDeposit<T>(params: any): AxiosPromise<T> {
    return this.$http.post('yimai-admin/doctor/cash/deposit/records/deposit', params)
  }

  public onAdjust<T>(params: any): AxiosPromise<T> {
    return this.$http.post('yimai-admin/doctor/cash/deposit/records/adjust', params)
  }

  public onAudit<T>(params: any): AxiosPromise<T> {
    return this.$http.post(`yimai-admin/doctor/cash/deposit/records/audit/${params.id}`)
  }

  public onAbandon<T>(params: any): AxiosPromise<T> {
    return this.$http.post(`yimai-admin/doctor/cash/deposit/records/abandon/${params.id}`)
  }

  public onSetLimitFee<T>(params: any): AxiosPromise<T> {
    return this.$http.post('yimai-admin/doctor/cash/deposit/set/limit', params)
  }
}

export default new DoctorCashDepositApi()