import { AxiosPromise } from 'axios'
import { IDocOrderQuery, responseData } from '../common/interface'
import { IOrderQuery, IUpdateOrderQuery } from '../common/interface/apiQuery'
import Service from '../common/service'
import { hasMock } from '../common/utils/publicUtils'
import Config from '../common/config'
const { mockApi } = Config.app
const mockApiPrefix = `${mockApi}/role`

class OrderApi extends Service {
	public ConfirmOrder<T>(params: { orderId: string }): AxiosPromise<T> {
		return this.$http.post(
			`/yimai-admin/order/primary/confirmOrder/${params.orderId}`
		)
	}

	public OrderDetail<T>(params: { orderId: string }): AxiosPromise<T> {
		return this.$http.get(`/yimai-admin/order/primary/detail/${params.orderId}`)
	}
	public DocOrderDetail<T>(params: IDocOrderQuery): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/order/primary/mainDetail', params)
	}

	public AllDocOrderList<T>(params: IOrderQuery): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApiPrefix}/list.json`)
		} else {
			return this.$http.post('/yimai-admin/order/primary/mainList', params)
		}
	}

	public UpdateOrder<T>(params: IUpdateOrderQuery): AxiosPromise<T> {
		return this.$http.post(
			`/yimai-admin/order/primary/update`,
			params
		)
	}
	public GetAllOrderStatus<T>(): AxiosPromise<T> {
		return this.$http.get('/yimai-admin/order/common/getAllOrderStatus')
	}

	public GetAllOrderType<T>(): AxiosPromise<T> {
		return this.$http.get('/yimai-admin/order/common/getAllOrderType')
	}

	public SearchDoctor<T>(params: { docName: string }): AxiosPromise<T> {
		// 医生下拉查询
		return this.$http.post('/yimai-admin/order/common/getDoctor', params)
	}

	public GetDoctorAddress<T>(params: { docid: number }): AxiosPromise<T> {
		return this.$http.get(
			`/yimai-admin/order/common/getDoctorAddress/${params.docid}`
		)
	}

	public SearchDocFans<T>(params: {
		docid: number
		userName?: string
		fansNo?: string
	}): AxiosPromise<T> {
		// 粉丝下拉查询
		return this.$http.post('/yimai-admin/order/common/getFans', params)
	}

	public GetSupplementOrderStatus<T>(): AxiosPromise<T> {
		return this.$http.get('/yimai-admin/order/common/getSupplementOrderStatus')
	}

	public GetSupplementOrderType<T>(): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/order/common/getSupplementOrderType')
	}
}

export default new OrderApi()
