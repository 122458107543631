import { AxiosPromise } from 'axios'
import {
	IAddUserQuery,
	IAdminUserQuery,
	IRoleListQuery,
	IUpdateUserQuery
} from '../common/interface/apiQuery'
import Service from '../common/service'
interface IUpdateRoleQuery {
	id: string
	roleType: string
	roleName: string
	privileges: number[]
}
class UserApis extends Service {
	public Login<T>(params: {
		username: string
		password: string
	}): AxiosPromise<T> {
		return this.$http.post(
			`/yimai-admin/login?username=${params.username}&password=${params.password}`
		)
	}
	public LoginOut<T>(): AxiosPromise<T> {
		return this.$http.get('/yimai-admin/logOut')
	}
	public GetUserInfo<T>(): AxiosPromise<T> {
		return this.$http.get('/yimai-admin/system/sys-user/userInfo')
	}

	public UpdatePwd<T>(params: {
		newPwd: string
		oldPwd: string
		userId: number
	}): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/system/sys-user/updPwd', params)
	}
	public resetPwd<T>(params: {
		pwd?: string
		userId: number
	}): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/system/sys-user/resetPwd', params)
	}
	// 添加新用户
	public AddNewUser<T>(params: IAddUserQuery): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/system/sys-user/add', params)
	}
	// 校验用户是否已存在
	public CheckUserName<T>(params: { userName: string }): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/system/sys-user/checkUserName', params)
	}
	// 禁用用户
	public DisabledUser<T>(params: { userid: string }): AxiosPromise<T> {
		return this.$http.post(
			`/yimai-admin/system/sys-user/disable/${params.userid}`
		)
	}
	// 启用用户
	public EnableUser<T>(params: { userid: string }): AxiosPromise<T> {
		return this.$http.post(
			`/yimai-admin/system/sys-user/enable/${params.userid}`
		)
	}
	// 获取所有医生列表
	public GetAllDoctors<T>(): AxiosPromise<T> {
		return this.$http.get('/yimai-admin/system/sys-user/getAllDoctors')
	}
	// 获取所有可选角色
	public GetAllRoles<T>(): AxiosPromise<T> {
		return this.$http.get('/yimai-admin/system/sys-user/getAllRoles')
	}

	// 获取运营分组权限
	public GetGroupPermission<T>(): AxiosPromise<T> {
		return this.$http.get('/yimai-admin/system/sys-user/getGroupPermission')
	}
	// 获取用户详细信息
	public GetUserPermissionInfo<T>(params: { userid: string }): AxiosPromise<T> {
		return this.$http.get(`/yimai-admin/system/sys-user/info/${params.userid}`)
	}
	// 获取用户管理列表
	public GetAdminUserList<T>(params: IAdminUserQuery): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/system/sys-user/list', params)
	}
	// 重置密码
	public ResetPassword<T>(params: {
		pwd: string
		userId: string
	}): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/system/sys-user/resetPwd', params)
	}
	// 解除运营人员与医生的绑定关系
	public UnbindDoctor<T>(params: {
		docid: string
		userId: string
	}): AxiosPromise<T> {
		return this.$http.get('/yimai-admin/system/sys-user/unbindDoctor', {
			params: { ...params }
		})
	}
	// 修改用户
	public UpdateUser<T>(params: IUpdateUserQuery): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/system/sys-user/upd', params)
	}
	// 获取用户资源
	public GetUserResources<T>(): AxiosPromise<T> {
		return this.$http.get('/yimai-admin/resources')
	}
	public GetDocNum<T>(): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/index')
	}
	// 获取所有可选部门
	public GetAllDepartment<T>(): AxiosPromise<T> {
		return this.$http.get('/yimai-admin/system/sys-user/getAllDepartments')
	}

	// 获取角色分类
	public RoleSort<T>(): AxiosPromise<T> {
		return this.$http.get('/yimai-admin/system/sys-role/getAllRoles')
	}
	// 获取角色列表
	public GetRoleList<T>(params: IRoleListQuery): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/system/sys-role/rolePage', params)
	}
	// 获取角色信息
	public RoleInfo<T>(params: { roleId: string }): AxiosPromise<T> {
		return this.$http.get(
			`/yimai-admin/system/sys-role/findRoleById/${params.roleId}`
		)
	}
	// 编辑角色
	public UpdateRole<T>(params: IUpdateRoleQuery): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/system/sys-role/mergeRole', params)
	}
	//
	public featureList<T>(params: { roleTypeId: string }): AxiosPromise<T> {
		return this.$http.get(
			`/yimai-admin/system/sys-role/queryMenu/${params.roleTypeId}`
		)
	}
}

export default new UserApis()
