import { action, computed, observable, runInAction } from 'mobx'
import moment from 'moment'
import FinanceApi from '../apis/Finance'
import OrderApi from '../apis/Order'
import {
	IAddSettingQuery,
	IAddSettingQueryV2,
	IConfigQuery,
	IDocMakeupQuery,
	IMakeupOrderQuery,
	IWithdrawQuery,
	IWithdrawSettleQuery
} from '../common/interface/apiQuery'
import {
	emptyFilter,
	pruneEmpty,
	randomString
} from '../common/utils/publicUtils'
import { preHandlerOrderDetail, preHandleWithdrawReviewDetail, handleList } from '../common/utils/handler'

class Finance {
	@observable public reviewData: any = {}
	@observable public docList: any[] = []
	@observable public reviewDetailData: any = {}
	@observable public makeupReviewData: any = {}
	@observable public userNameList: any[] = []
	@observable public batchReviewList: any[] = []
	@observable public makeupDetail: any = {}
	@observable public withdrawData: any = {}
	@observable public configData: any = {}
	@observable public configSearchData: any = {}
	@observable public docAddress: any[] = []
	@observable public docSettingDetail: any = {}
	@observable public docGlobalSetting: any = {
		distinguishFansSource: true,
		appointSwitch: false,
		operationSwitch: false
	}
	@observable public docMakeupDate: any = {}
	@observable public thirdPartFee: any[] = []

	@computed public get docMakeupList() {
		const { list } = this.docMakeupDate
		return list
			? list.map((item: any) => Object.assign({ key: randomString() }, item))
			: []
	}
	@computed public get effectiveSettings() {
		const { effectiveSettings } = this.docSettingDetail
		return effectiveSettings
			? effectiveSettings.map((item: any) =>
				Object.assign({ key: randomString() }, item)
			)
			: []
	}

	@computed public get historySettings() {
		const { historySettings } = this.docSettingDetail
		return historySettings
			? historySettings.map((item: any) =>
				Object.assign({ key: randomString() }, item)
			)
			: []
	}
	@computed public get configSearchList() {
		const { effectiveSettings } = this.configSearchData
		return effectiveSettings
			? effectiveSettings.map((item: any) =>
				Object.assign({ key: randomString() }, item)
			)
			: []
	}
	@computed public get configTotal(): number {
		return this.configData.total
	}

	@computed public get configList() {
		const { list } = this.configData
		return list
			? list.map((item: any) => Object.assign({ key: randomString() }, item))
			: []
	}
	@computed public get withdrawTotal(): number {
		return this.withdrawData.total
	}
	@computed public get reviewTotal(): number {
		return this.reviewData.total
	}
	@computed public get docMakeupTotal(): number {
		return this.docMakeupDate.total
	}
	@computed public get withdrawList() {
		const { list } = this.withdrawData
		return list
			? list.map((item: any) => Object.assign({ key: randomString() }, item))
			: []
	}
	@computed public get reviewTable() {
		const { list } = this.reviewData
		return list
			? list.map((item: any) => Object.assign({ key: randomString() }, item))
			: []
	}
	@computed public get makeupReviewTotal(): number {
		return this.makeupReviewData.total
	}

	@computed public get makeupReviewList() {
		const { list } = this.makeupReviewData
		return list
			? list.map((item: any) => Object.assign({ key: randomString() }, item))
			: []
	}
	@action public async getAllDoctor(params: { docName: string }) {
		const { data: res } = await OrderApi.SearchDoctor(params)
		this.docList = res
	}
	@action public async getReviewData(params: IWithdrawQuery) {
		const { data: res } = await FinanceApi.WithdrawReviewList(
			pruneEmpty(params)
		)
		const { list, startRow } = res
		let nextList = handleList(list, startRow)
		nextList = nextList.map((item) => {
			return preHandleWithdrawReviewDetail(item)
		})

		this.reviewData = {
			...{}, ...res, ...{
				list: nextList
			}
		}
	}
	@action public async batchSettle(params: { ids: any[] }) {
		try {
			await FinanceApi.BatchSettle(params)
			return Promise.resolve('success')
		} catch (error) {
			return Promise.reject(error)
		}
	}

	@action public async getReviewDetailData(params: { id: string }) {
		const { data: res } = await FinanceApi.WithdrawReviewDetail(params)
		this.reviewDetailData = preHandleWithdrawReviewDetail(res)
	}

	@action public async byWithdrawRview(params: { id: string }) {
		try {
			await FinanceApi.ByWithdrawReview(params)
			return Promise.resolve('success')
		} catch (error) {
			return Promise.reject(error)
		}
	}
	@action public async getUserName(params: {}) {
		// const {data:res} = await OrderApi.SearchDocFans
	}

	@action public async getMakeupReviewData(params: IMakeupOrderQuery) {
		const { data: res } = await FinanceApi.GetMakeupOrderList(
			pruneEmpty(params)
		)
		const { list, startRow } = res
		const nextList = handleList(list, startRow)
		this.makeupReviewData = {
			...{}, ...res, ...{
				list: nextList
			}
		}
	}

	@action public async getBatchReview(params: {
		checkType: string
		endTime: string
		startTime: string
	}) {
		const { data: res } = await FinanceApi.MakeupOrderBatchSearch(params)
		this.batchReviewList = res
	}

	@action public async getMakeupReviewDetail(params: { orderId: string }) {
		const { data: res } = await FinanceApi.GetMakeupOrderDetail(params)
		this.makeupDetail = res
	}

	@action public async makeupTurndown(params: {
		orderId: string
		reason: string
	}) {
		try {
			await FinanceApi.RejectMakeupOrder(params)
			return Promise.resolve('success')
		} catch (error) {
			return Promise.reject(error)
		}
	}

	@action public async makeupPassed(params: {
		orderId: string
		doctorPercentage: string | number
		hospitalPercentage: string | number
		platformPercentage: string | number
		pictures: any[]
	}) {
		try {
			await FinanceApi.ConfirmByReview(params)
			return Promise.resolve('success')
		} catch (error) {
			return Promise.reject(error)
		}
	}

	@action public async makeupPassedBatch(params: {
		orderIds: Array<string>
		doctorPercentage: string | number
		hospitalPercentage: string | number
		platformPercentage: string | number
	}) {
		try {
			await FinanceApi.ConfirmByReviewBatch(params)
			return Promise.resolve('success')
		} catch (error) {
			return Promise.reject(error)
		}
	}

	@action public async getWithdrawData(params: IWithdrawSettleQuery) {
		const { data: res } = await FinanceApi.WithDrawSettleList(
			pruneEmpty(params)
		)
		const { list, startRow } = res
		const nextList = handleList(list, startRow)

		this.withdrawData = {
			...{}, ...res, ...{
				list: nextList
			}
		}
	}

	@action public async getConfigInfo(params: IConfigQuery) {
		const { data: res } = await FinanceApi.getSettings(params)
		const { list, startRow } = res
		const nextList = handleList(list, startRow)

		this.configData = {
			...{}, ...res, ...{
				list: nextList
			}
		}
	}

	@action public async searchDocSetting(params: { docid: string }) {
		const { data: res } = await FinanceApi.getDocSettings(params)
		this.configSearchData = res
	}
	@action public async updateSetting(params: IAddSettingQuery) {
		try {
			await FinanceApi.mergeSettings(pruneEmpty(params))
			return Promise.resolve('success')
		} catch (error) {
			return Promise.reject(error)
		}
	}

	@action public async updateSettingV2(params: IAddSettingQueryV2) {
		try {
			await FinanceApi.mergeSettingsv2(pruneEmpty(params))
			return Promise.resolve('success')
		} catch (error) {
			return Promise.reject(error)
		}
	}

	@action public async getDocHos(params: { docid: number }) {
		const { data: res } = await OrderApi.GetDoctorAddress(params)
		this.docAddress = res
	}

	@action public async getDoctorAddress(params: { docid: string }) {
		const { data: res } = await FinanceApi.getDoctorAddress(params)
		this.docAddress = res
	}

	@action public async getDocSettingDetail(params: { docid: string }) {
		try {
			const { data: res } = await FinanceApi.getDocSettingDetail(params)
			this.docSettingDetail = res
			return Promise.resolve(res)
		} catch (error) {
			return Promise.reject(error)
		}
	}
	@action public async getDocGlobalSetting(params: { docid: string }) {
		try {
			const { data: res } = await FinanceApi.getDocGlobalSetting(params)
			const result = {
				...{}, ...res, ...{
					distinguishFansSource: res.distinguishFansSource === 'ON' ? true : false,
					appointSwitch: res.appointSwitch === 'ON' ? true : false,
					operationSwitch: res.operationSwitch === 'ON' ? true : false
				}
			}
			this.docGlobalSetting = result
			return Promise.resolve(result)
		} catch (error) {
			return Promise.reject(error)
		}
	}
	@action public async setDocGlobalSetting(params: {
		distinguishFansSource?: boolean,
		appointSwitch?: boolean,
		operationSwitch?: boolean
	}) {
		const { distinguishFansSource } = params
		// const result = { ...{}, ...this.docGlobalSetting, ...params }
		if (distinguishFansSource != undefined || distinguishFansSource != null) {
			this.docGlobalSetting.distinguishFansSource = distinguishFansSource
		}

	}
	@action public async addDocTaxScale(params: {
		docid: string
		taxScale: number
	}) {
		try {
			await FinanceApi.addDocTaxScale(params)
			return Promise.resolve('success')
		} catch (error) {
			return Promise.reject(error)
		}
	}

	@action public async getDocMakeupList(params: IDocMakeupQuery) {
		const { data: res } = await FinanceApi.docMakeupList(pruneEmpty(params))
		const { list, startRow } = res
		const nextList = handleList(list, startRow)

		this.docMakeupDate = {
			...{}, ...res, ...{
				list: nextList
			}
		}
	}

	@action public async getThirdPartFee() {
		const { data: res } = await FinanceApi.thirdpartFeeList()
		runInAction(() => {
			this.thirdPartFee = res.map((c: any) =>
				Object.assign({ key: randomString() }, c)
			)
		})
	}

	@action public async confirmPay(params: {
		id: string
		providerId: string
		pictures: string[]
	}) {
		try {
			await FinanceApi.confirmPay(params)
			return Promise.resolve('success')
		} catch (error) {
			return Promise.reject(error)
		}
	}
}

export default Finance
