import { AxiosPromise } from 'axios'
import Config from '../common/config'
import { IDocQuery, ListBase } from '../common/interface/apiQuery'
import Service from '../common/service'
import { hasMock } from '../common/utils/publicUtils'
const { mockApi } = Config.app
const mockApiPrefix = `${mockApi}/fansCashout`

export interface IFansCashoutQuery extends ListBase {
  fansId?: string | number
  startDate?: string | undefined
  endDate?: string | undefined
	status?: string | number
}

export interface IFansCashoutDetail {
  cashNo: string
  money: number
  frozenMoney: number
  amount: number
  fansId: string | number
  account: string
  status: number
  payPictures: any
}

export interface IFansWalletListQuery extends ListBase {
  fansId: string | number
}

export enum CashoutStatus {
  waitCashout = 10,
  cashouted = 20
}

class FansCashoutApi extends Service {
  public list<T>(params: IFansCashoutQuery): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApiPrefix}/list.json`)
		} else {
			return this.$http.post('yimai-admin/fans/withdraw/list', params)
		}
  }
  
  public detail<T>(params: { cashNo: string | number}): AxiosPromise<T> {
    if (hasMock(false)) {
			return this.$http.get(`${mockApiPrefix}/detail.json`)
		} else {
			return this.$http.get(`yimai-admin/fans/withdraw/fansPresentDetail/${params.cashNo}`)
		}
  }

  public confirm<T>(params: {fansId: string | number}): AxiosPromise<T> {
    if (hasMock(false)) {
      return this.$http.get(`${mockApi}/success.json`)
    } else {
      return this.$http.post(`yimai-admin/fans/withdraw/confirmPay`, params)
    }
  }

  public walletList<T>(params: any): AxiosPromise<T> {
    if (hasMock(false)) {
      return this.$http.get(`${mockApi}/success.json`)
    } else {
      return this.$http.post(`yimai-admin/fans/withdraw/Billdetails`, params)
    }
  }
}

export default new FansCashoutApi()

