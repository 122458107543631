import { AxiosPromise } from 'axios'
import { IAddMakeupQuery, IMakeupQuery } from '../common/interface/apiQuery'
import Service from '../common/service'
class MakeupApi extends Service {
	public getAllMakeupOrder<T>(params: {
		docid?: number
		pageNo: number
		pageSize: number
	}): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/order/supplement/mainList', params)
	}

	public getDocMakeupOrder<T>(params: IMakeupQuery): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/order/supplement/mainDetail', params)
	}
	public getOrderStatus<T>(): AxiosPromise<T> {
		return this.$http.get('/yimai-admin/order/common/getSupplementOrderStatus')
	}

	public getOrderTypes<T>(): AxiosPromise<T> {
		return this.$http.get('/yimai-admin/order/common/getSupplementOrderType')
	}
	public getMakeupDetail<T>(params: { orderId: string }): AxiosPromise<T> {
		return this.$http.get(
			`/yimai-admin/order/supplement/detail/${params.orderId}`
		)
	}
	public SupplementAdd<T>(params: IAddMakeupQuery): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/order/supplement/add', params)
	}
	public orderJudge<T>(params: {
		docid: string
		orderType: string
	}): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/order/supplement/judge', params)
	}
	public selectTime<T>(params: { docid: string }): AxiosPromise<T> {
		return this.$http.post(`/yimai-admin/order/common/selectTime/${params.docid}`)
	}
}

export default new MakeupApi()
