import moment from 'moment'
import * as Constants from '../../common/constants'
import * as Dictionary from '../../common/dictionary'
import {
	FansSource,
	OrderType,
	PayChannelType,
	RefundStatus,
	RefundType,
	WithdrawDetail
} from '../../common/enums'
import * as Utils from './publicUtils'

export function preHandlerOrderDetail(order: any) {
	let result: any
	const { orderStatus, orderAmount, subStatus, orderType, payAmount, isEnableRefund } = order
	const needPay = orderAmount > 0 ? true : false

	let _handleStatusDesc = function (order: any) {
		let
			title,
			subTitle,
			fansTitle,
			fansSubTitle,
			docTitle,
			docSubTitle,
			statusDesc,
			statusDetailDesc

		let {
			orderStatus,
			subStatus,
			orderType,
			orderTypeDesc,
			canBookingCountdown,
			bookingCountdown,
			bookingCountdwonDay,
			canCountdown,
			canModifyCountdown,
			needPay
		} = order

		let obj = {
			title,
			subTitle,
			fansTitle,
			fansSubTitle,
			docTitle,
			statusDesc,
			statusDetailDesc
		}

		return { ...{}, ...order, ...obj }
	}

	result = {
		needPay,
		btnCanRefund: Dictionary.ORDER_BTN_CONF[orderType]['refound'][orderStatus] && isEnableRefund == Constants.ORDER_REFUND_ENABLE && payAmount > 0,
		btnUpdate:
			Dictionary.ORDER_BTN_CONF[orderType]['update_order'][orderStatus],
		btnConfirm: Dictionary.ORDER_BTN_CONF[orderType]['confirm'][orderStatus]
	}

	result = { ...{}, ...order, ...result }
	// result = _handleStatusDesc(result)

	return result
}

export function preHandleRefundDetail(refund: any) {
	const {
		applyDate,
		applyMoney,
		applyStatus,
		bookDate,
		orderStatus,
		orderSubStatus,
		orderType,
		payAmount,
		payChannel,
		orderAmount,
		payTime,
		source,
		refundType,
		isShowSubmit,
		isShowCancle,
		checkDate
	} = refund

	const needPay = orderAmount > 0 ? true : false
	const isDiagnosis = orderType === OrderType.ORDER_TYPE_DIAGNOSIS
	const isSurgery = orderType === OrderType.ORDER_TYPE_SURGERY
	const isCustom = orderType === OrderType.ORDER_TYPE_CUSTOM

	let result: any = {
		needPay,
		isDiagnosis,
		isSurgery,
		isCustom,
		applyDateFormat: getApplyDateFormat(applyDate),
		applyMoneyFormat: Utils.formatMoney(applyMoney),
		applyStatusDesc: getApplyStatusDesc(applyStatus),
		statusTheme: getStatusTheme(applyStatus),
		statusIsSizeFive: getStatusIsSizeFive(applyStatus),
		statusIsSizeFour: getStatusIsSizeFour(applyStatus),
		bookDateFormat: getBookDateFormat(bookDate, orderType), // utfOffset8(bookDate, Constants.DATE_FORMAT_YYYYMMDD_HHMM),
		checkDateFormat: !!checkDate
			? utfOffset8(checkDate, Constants.DATE_FORMAT_YYYYMMDD_HHMM)
			: '-',
		orderStatusDesc: getOrderStatusDesc(
			orderStatus,
			orderSubStatus,
			needPay,
			isDiagnosis,
			isSurgery,
			isCustom
		),
		orderTypeDesc: getOrderTypeDesc(orderType),
		payAmountFormat: Utils.formatMoney(payAmount),
		orderAmountFormat: Utils.formatMoney(orderAmount),
		payChannelDesc: getPayChannelDesc(payChannel),
		payTimeFormat: utfOffset8(payTime, Constants.DATE_FORMAT_YYYYMMDD_HHMM),
		sourceDesc: getSourceDesc(source),
		refundTypeDesc: getRefundTypeDesc(refundType),
		btnCanCancel: getBtnCanCancel(applyStatus, isShowCancle),
		btnCanReCreate: getBtnCanReCreate(applyStatus, isShowSubmit),
		btnCanReject: getBtnCanReject(applyStatus),
		btnCanAccept: getBtnCanAccept(applyStatus),
		btnCanReAccept: getBtnCanReAccept(applyStatus),
		btnCanManualRefund: getBtnCanManualRefund(applyStatus)
	}

	result = { ...{}, ...refund, ...result }

	return result
}

export function getOrderStatusDesc(
	status: string,
	subStatus: string,
	needPay: boolean,
	isDiagnosis: boolean,
	isSurgery: boolean,
	isCustom: boolean
) {
	let result = ''

	switch (parseInt(status)) {
		case Dictionary.PAYMENT_SUCCESS:
			if (needPay) {
				result = '支付成功'
			} else {
				result = '面诊已确认'
			}
		case Dictionary.PENDING_CONFIRMATION_OF_ORDER:
			if (needPay) {
				result = '待手术'
			} else {
				result = '待面诊'
			}
			break
		case Dictionary.ORDER_COMPLETION:
			result = '已完成'
			break
		case Dictionary.REFUND:
			result = '退款'
			break
	}

	return result
}

export function preHandleWithdrawReviewDetail(detail: any) {
	let result
	const { applyStatus } = detail

	const getApplyStatusDesc = (applyStatus: number) => {
		let result = ''

		switch (Number(applyStatus)) {
			case WithdrawDetail.WAIT_AUDIT:
				result = '待审核'
				break
			case WithdrawDetail.ACCEPT:
				result = '审核通过'
				break
			case WithdrawDetail.TIMEOUT:
				result = '超时'
				break
			case WithdrawDetail.SUCCESS:
				result = '已打款'
				break
			case WithdrawDetail.FAIL:
				result = '审核失败'
				break
		}

		return result
	}

	const getStatusTheme = (applyStatus: number) => {
		let result = 'default'

		switch (Number(applyStatus)) {
			case WithdrawDetail.ACCEPT:
			case WithdrawDetail.SUCCESS:
				result = 'success'
				break
			case WithdrawDetail.TIMEOUT:
			case WithdrawDetail.FAIL:
				result = 'warn'
				break
		}

		return result
	}

	const getShowBtnAudit = (applyStatus: number) => {
		const status = Number(applyStatus)
		return (
			status === WithdrawDetail.WAIT_AUDIT || status === WithdrawDetail.TIMEOUT
		)
	}

	const getShowBtnSettle = (applyStatus: number) => {
		const status = Number(applyStatus)
		return status === WithdrawDetail.ACCEPT
	}

	const getShowSettleApply = (applyStatus: number) => {
		const status = Number(applyStatus)
		return status === WithdrawDetail.ACCEPT || status === WithdrawDetail.SUCCESS
	}

	result = {
		applyStatusDesc: getApplyStatusDesc(applyStatus),
		statusTheme: getStatusTheme(applyStatus),
		showBtnAudit: getShowBtnAudit(applyStatus),
		showBtnSettle: getShowBtnSettle(applyStatus),
		showSettleApply: getShowSettleApply(applyStatus)
	}

	result = { ...{}, ...detail, ...result }
	return result
}

function getApplyDateFormat(applyDate: string) {
	return utfOffset8(applyDate, Constants.DATE_FORMAT_YYYYMMDD_HHMM)
}

function getApplyStatusDesc(applyStatus: string) {
	let result = ''

	switch (parseInt(applyStatus)) {
		case RefundStatus.waitAudit:
			result = '待财务审核'
			break
		case RefundStatus.waitDoctorAudit:
			result = '待医生审核'
			break
		case RefundStatus.canceled:
			result = '已撤销'
			break
		case RefundStatus.rejected:
			result = '被财务驳回'
			break
		case RefundStatus.doctorRejected:
			result = '被医生驳回'
			break
		case RefundStatus.refunding:
			result = '待退款'
			break
		case RefundStatus.refundFail:
			result = '退款失败'
			break
		case RefundStatus.refunded:
			result = '已退款'
			break
		case RefundStatus.closed:
			result = '已关闭'
			break
		case RefundStatus.manualRefund:
			result = '待线下退款'
			break
	}

	return result
}

export function getOrderTypeDesc(orderType: string) {
	let result = ''

	switch (parseInt(orderType)) {
		case OrderType.ORDER_TYPE_DIAGNOSIS:
			result = '面诊'
			break
		case OrderType.ORDER_TYPE_SURGERY:
			result = '手术'
			break
		case OrderType.ORDER_TYPE_CUSTOM:
			result = '项目'
			break
	}

	return result
}

function getPayChannelDesc(payChannel: string) {
	let result = ''

	switch (parseInt(payChannel)) {
		case PayChannelType.PAY_CHANNEL_WXPAY:
			result = '微信'
			break
		case PayChannelType.PAY_CHANNEL_ALIPAY:
			result = '支付宝'
			break
		case PayChannelType.PAY_CHANNEL_LIANLIANPAY:
			result = '连连支付'
			break
		case PayChannelType.PAY_CHANNEL_OFFLINE:
			result = '线下支付'
			break
	}

	return result
}

export function getSourceDesc(source: string) {
	let result = ''

	switch (parseInt(source)) {
		case FansSource.FANS_SOURCE_CLINIC:
			result = '门诊'
			break
		case FansSource.FANS_SOURCE_CONSULT:
			result = '口碑'
			break
	}

	return result
}

function getRefundTypeDesc(refundType: string) {
	let result = ''

	switch (parseInt(refundType)) {
		case RefundType.ALL:
			result = '全额退款'
			break
		case RefundType.PARTIAL:
			result = '部分退款'
			break
	}

	return result
}

function getStatusTheme(applyStatus: string) {
	let result = ''

	switch (parseInt(applyStatus)) {
		case RefundStatus.closed:
		case RefundStatus.waitAudit:
		case RefundStatus.waitDoctorAudit:
		case RefundStatus.canceled:
			result = 'default'
			break
		case RefundStatus.rejected:
		case RefundStatus.doctorRejected:
			result = 'warn'
			break
		case RefundStatus.refunding:
			result = 'default'
			break
		case RefundStatus.refundFail:
			result = 'warn'
			break
		case RefundStatus.refunded:
			result = 'success'
			break
	}

	return result
}

const getStatusIsSizeFive = (applyStatus: string) => {
	const intApplyStatus = parseInt(applyStatus)
	if (intApplyStatus === RefundStatus.waitAudit ||
		intApplyStatus === RefundStatus.waitDoctorAudit ||
		intApplyStatus === RefundStatus.rejected ||
		intApplyStatus === RefundStatus.doctorRejected ||
		intApplyStatus === RefundStatus.manualRefund
	) {
		return true
	}
	return false
}

const getStatusIsSizeFour = (applyStatus: string) => {
	const intApplyStatus = parseInt(applyStatus)
	if (
		intApplyStatus === RefundStatus.refundFail) {
		return true
	}
	return false
}

function getBtnCanCancel(applyStatus: string, isShowCancle: any) {
	return (
		isShowCancle !== '0' && parseInt(applyStatus) === RefundStatus.waitAudit
	)
}

function getBtnCanReCreate(applyStatus: string, isShowSubmit: any) {
	const intApplyStatus = parseInt(applyStatus)
	return (
		isShowSubmit !== '0' &&
		(intApplyStatus === RefundStatus.canceled ||
			intApplyStatus === RefundStatus.rejected)
	)
}

function getBtnCanReject(applyStatus: string) {
	const intApplyStatus = parseInt(applyStatus)
	return intApplyStatus === RefundStatus.waitAudit || intApplyStatus === RefundStatus.doctorRejected
}

function getBtnCanAccept(applyStatus: string) {
	const intApplyStatus = parseInt(applyStatus)
	return intApplyStatus === RefundStatus.waitAudit || intApplyStatus === RefundStatus.doctorRejected
}

function getBtnCanReAccept(applyStatus: string) {
	return parseInt(applyStatus) === RefundStatus.refundFail
}

function getBtnCanManualRefund(applyStatus: string) {
	return parseInt(applyStatus) === RefundStatus.manualRefund
}

function getBookDateFormat(bookDate: number, orderType: string) {
	let result = ''
	if (!bookDate) return result

	switch (parseInt(orderType)) {
		case OrderType.ORDER_TYPE_DIAGNOSIS:
			result = utfOffset8(bookDate, Constants.DATE_FORMAT_YYYYMMDD)
			break
		case OrderType.ORDER_TYPE_SURGERY:
		case OrderType.ORDER_TYPE_CUSTOM:
			result = utfOffset8(bookDate, Constants.DATE_FORMAT_YYYYMMDD)
			break
	}

	return result
}

export function utfOffset8(
	originDatetime: moment.Moment | string | number,
	format?: string
) {
	return moment(originDatetime)
		.utcOffset(8)
		.format(format || Constants.DATE_FORMAT_YYYYMMDD_HHMM)
}

export function handleList(list: any[], startRow: number) {
	const nextList = list.map((item: any, index: number) => {
		item._no = startRow + index
		return item
	})

	return nextList
}
