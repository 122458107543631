import { action, computed, observable, runInAction } from 'mobx'
import moment from 'moment'
import React from 'react'
import FinanceApi from '../../apis/Finance'
import OrderApi from '../../apis/Order'
import * as contants from '../../common/dictionary'
import { Dict, IDocOrderQuery } from '../../common/interface'
import { IOrderQuery } from '../../common/interface/apiQuery'
import { IRangePiker } from '../../common/interface/index'
import { handleList, preHandlerOrderDetail } from '../../common/utils/handler'
import { pruneEmpty, randomString } from '../../common/utils/publicUtils'

const CheckedList = [
	{
		label: '本月',
		value: '1',
		checked: false
	},
	{
		label: '近7日',
		value: '2',
		checked: false
	},
	{
		label: '近30日',
		value: '3',
		checked: false
	},
	{
		label: '近3个月',
		value: '4',
		checked: false
	}
]
class OrderPrivateStore {
	@observable public searchObj: {
		rangeDate: IRangePiker
		[key: string]: any
	} = {
			docid: '',
			rangeDate: [],
			addressId: '',
			orderNo: '',
			orderStatus: '',
			orderType: '',
			patientType: '',
			fansNo: '',
			projectName: '',
			searchType: '',
			timeSection: ''
		}
	@observable public selected: string = '1'
	@observable public CheckedList: {
		label: string
		checked: boolean
		value: string
	}[] = CheckedList
	@observable public completeSelect: string = ''
	@observable public completeData: any[] = observable.array([])
	@observable public fansDataSource: any[] = observable.array([])
	@observable public hospAddress: any[] = observable.array([])
	@observable public orderStatus: any[] = observable.array([])
	@observable public allOrderType: any[] = observable.array([])
	@observable public position: 'order' | 'user' = 'order'
	@observable public orderDataSource: any = {}
	@observable public orderList: any[] = observable.array([])
	@observable public orderCardData: Dict = {}
	@observable public orderSettleData: any = {}
	@observable public docOrderSettleData: any = {}
	@observable public allOrderStatus: any[] = observable.array([])

	@computed public get orderSettleList() {
		const { list } = this.orderSettleData
		return list
			? list.map((item: any) => Object.assign({ key: randomString() }, item))
			: []
	}
	@computed public get docOrderSettleList() {
		const { list } = this.docOrderSettleData
		return list
			? list.map((item: any) => Object.assign({ key: randomString() }, item))
			: []
	}
	@computed public get orderSettleTotal(): number {
		return this.orderSettleData.total
	}
	@computed public get docOrderSettleTotal(): number {
		return this.docOrderSettleData.total
	}
	@computed public get orderTotal(): number {
		const { dataList } = this.orderDataSource
		return dataList ? dataList.total : 0
	}

	@action public async getDoctorSuggest(docName: string) {
		const { data: res } = await OrderApi.SearchDoctor({ docName })
		runInAction(() => {
			this.completeData = res.map(
				(c: { id: number; docName: string } | null) => {
					const obj = c && {
						value: c.id,
						text: c.docName
					}
					return obj
				}
			)
		})
		return res
	}
	@action public updatePosition(value: 'order' | 'user') {
		this.position = value
	}
	@action public async getDocAddress(params: { docid: number }) {
		const { data: res } = await OrderApi.GetDoctorAddress(params)
		runInAction(() => {
			this.hospAddress = res
		})
		return res
	}
	@action public async getStatus() {
		const { data: res } = await OrderApi.GetAllOrderStatus()
		runInAction(() => {
			this.allOrderStatus = res
			this.orderStatus = res
		})
	}
	@action public async getAllOrderType() {
		const { data: res } = await OrderApi.GetAllOrderType()
		runInAction(() => {
			this.allOrderType = res
		})
	}
	@action public selectChangeHandle(selected: string, name: string) {
		if (name === 'selected') {
			this.selected = selected
		} else if (name === 'orderType') {
			const statusMap: { [key: string]: string[] } = {
				1: ['10', '20', '40', '50', '60'],
				2: ['10', '20', '40', '50', '30'],
				3: ['30', '20', '40', '50', '70'],
				all: ['10', '20', '30', '40', '50', '60', '70', '90']
			}
			this.orderStatus = this.allOrderStatus.filter(c =>
				statusMap[selected || 'all'].includes(c.id)
			)
			this.orderStatus = this.orderStatus.map(c => {
				if (['20', '30', '40', '70'].includes(c.id)) {
					c.name = selected === '1' && c.id === '20' ? '待面诊' : c.name
					c.name = selected === '1' && c.id === '40' ? '已面诊' : c.name
					c.name = selected === '2' && c.id === '20' ? '待手术' : c.name
					c.name = selected === '2' && c.id === '30' ? '待确认' : c.name
					c.name = selected === '2' && c.id === '40' ? '已手术' : c.name
					c.name = selected === '3' && c.id === '20' ? '待手术' : c.name
					c.name = selected === '3' && c.id === '30' ? '待确认' : c.name
					c.name = selected === '3' && c.id === '40' ? '已手术' : c.name
					c.name = selected === '3' && c.id === '70' ? '待约时间' : c.name
				}
				return c
			})
			this.searchObj[name] = selected
		} else {
			this.searchObj[name] = selected
		}
	}

	@action public checkHandleChange(index: number) {
		const list = this.CheckedList.map((c, i) => {
			c.checked = index === i ? (c.checked ? false : true) : false
			return c
		})
		const obj = list.find(c => c.checked)
		this.searchObj.timeSection = obj ? obj.value : ''
		this.CheckedList = list
		this.searchObj.rangeDate = []
	}

	@action public dateChangeHandle(
		dates: IRangePiker,
		dateStrings: [string, string]
	) {
		this.searchObj.rangeDate = dates
		this.searchObj.timeSection = ''
		this.CheckedList = this.CheckedList.map(c =>
			Object.assign(c, { checked: false })
		)
	}
	@action public iptChangeHandle(
		event: React.ChangeEvent<HTMLInputElement>,
		name: string
	) {
		this.searchObj[name] = event.target.value
	}

	@action public async getData(pageNo: number = 1, pageSize: number = 10) {
		this.position === 'order'
			? this.getAllDocList(
				pruneEmpty({
					pageNo,
					pageSize,
					docid: this.completeSelect ? Number(this.completeSelect) : ''
				})
			)
			: this.getDocOrderDetail(
				pruneEmpty({
					pageNo,
					pageSize,
					docid: this.searchObj.docid,
					startDate: this.searchObj.rangeDate[0]
						? this.searchObj.rangeDate[0].format('YYYY-MM-DD')
						: '',
					endDate: this.searchObj.rangeDate[1]
						? this.searchObj.rangeDate[1].format('YYYY-MM-DD')
						: '',
					addressId: this.selected === '1' ? this.searchObj.addressId : '',
					orderStatus:
						this.selected === '1' ? this.searchObj.orderStatus : '',
					orderType: this.selected === '1' ? this.searchObj.orderType : '',
					patientType:
						this.selected !== '1' ? this.searchObj.patientType : '',
					fansNo: this.selected !== '1' ? this.searchObj.fansNo : '',
					projectName:
						this.selected === '1' ? this.searchObj.projectName : '',
					searchType: this.selected,
					timeSection: this.searchObj.timeSection
				})
			)
	}
	@action public completeHandleChange(value: any): any {
		!value && (this.completeSelect = '')
	}

	@action public selectHandle(value: any) {
		this.completeSelect = value
	}
	@action public async getDocOrderDetail(params: IDocOrderQuery) {
		const { data: res } = await OrderApi.DocOrderDetail(params)
		res.userSumFlow = res.sumFlow
		delete res.sumFlow
		this.getOrderCard(res)

		const { dataList } = res
		const { list, startRow } = dataList
		let nextList = handleList(list, startRow)
		nextList = nextList.map((item) => (
			preHandlerOrderDetail(item)
		))

		const nextDataList = {
			...{},
			...dataList,
			...{
				list: nextList
			}
		}

		this.orderDataSource = {
			...{},
			...res,
			...{
				dataList: nextDataList
			}
		}

		this.orderList = nextList
	}

	@action public async getAllDocList(params: {
		docid?: number
		pageNo: number
		pageSize: number
	}) {
		const { data: res } = await OrderApi.AllDocOrderList(params)
		res.sumReceived = res.actuallySumReceived
		delete res.actuallySumReceived
		this.getOrderCard(res)
		runInAction(() => {
			const { dataList } = res
			const { list, startRow } = dataList

			const nextList = handleList(list, startRow)

			this.orderDataSource = {
				...{},
				...dataList,
				...{
					list: nextList
				}
			}
			this.orderList = nextList
		})
		return res
	}

	@action public getOrderCard({
		actuallySumReceived,
		sumReceived,
		orderCount,
		appointmentSumCount,
		doctorIncome,
		operationSumCount,
		sumFlow,
		platformIncome,
		userSumFlow
	}: {
		[key: string]: number
	}) {
		this.orderCardData = pruneEmpty({
			actuallySumReceived,
			orderCount,
			appointmentSumCount,
			doctorIncome,
			operationSumCount,
			sumFlow,
			platformIncome,
			userSumFlow,
			sumReceived
		})
	}
	@action public async getOrderSettleData(params: IOrderQuery) {
		const { data: res } = await FinanceApi.GetOrderSettleData(
			pruneEmpty(params)
		)
		const { dataList } = res
		const { list, startRow } = dataList
		const nextList = handleList(list, startRow)

		const nextDataList = {
			...{},
			...dataList,
			...{
				list: nextList
			}
		}

		this.orderSettleData = nextDataList

		this.getOrderCard(res)
	}
	@action public async getDocOrderSettleData(params: IDocOrderQuery) {
		const { data: res } = await FinanceApi.GetDocSettleData(pruneEmpty(params))
		const { dataList } = res
		const { list, startRow } = dataList
		const nextList = list

		const nextDataList = {
			...{},
			...dataList,
			...{
				list: nextList
			}
		}

		this.docOrderSettleData = nextDataList

		this.getOrderCard(res)
	}
}

export default OrderPrivateStore
