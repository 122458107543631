import { action, computed, observable, runInAction } from 'mobx'
import FinanceApi from '../apis/Finance'
import OrderApi from '../apis/Order'
import { Dict, IDocOrderQuery } from '../common/interface'
import { IOrderQuery, IUpdateOrderQuery } from '../common/interface/apiQuery'
import { preHandlerOrderDetail, handleList } from '../common/utils/handler'
import { pruneEmpty, randomString } from '../common/utils/publicUtils'

class OrderStore {
	@observable public orderData: { [key: string]: any }
	@observable public orderList: any[]
	@observable public orderDataSource: any = {}
	@observable public orderCardData: Dict
	@observable public makeUpTableList: {}[]
	@observable public MakeUpRecord: {}[]
	@observable public completeData: any[]
	@observable public docAddress: any[]
	@observable public allOrderStatus: any[]
	@observable public allOrderType: any[] = []
	@observable public fansNameDataSource: any[] = []
	@observable public orderSettleData: any = {}
	@observable public docOrderSettleData: any = {}

	@computed public get orderSettleList() {
		const { list } = this.orderSettleData
		return list
			? list.map((item: any) => Object.assign({ key: randomString() }, item))
			: []
	}
	@computed public get docOrderSettleList() {
		const { list } = this.docOrderSettleData
		return list
			? list.map((item: any) => Object.assign({ key: randomString() }, item))
			: []
	}
	@computed public get orderSettleTotal(): number {
		return this.orderSettleData.total
	}
	@computed public get docOrderSettleTotal(): number {
		return this.docOrderSettleData.total
	}
	@computed public get total() {
		const { dataList } = this.orderDataSource
		return dataList ? dataList.total : 0
	}
	@action public getOrderCard({
		actuallySumReceived,
		orderCount,
		appointmentSumCount,
		doctorIncome,
		operationSumCount,
		sumFlow,
		platformIncome,
		userSumFlow
	}: {
		[key: string]: number
	}) {
		this.orderCardData = pruneEmpty({
			actuallySumReceived,
			orderCount,
			appointmentSumCount,
			doctorIncome,
			operationSumCount,
			sumFlow,
			platformIncome,
			userSumFlow
		})
	}
	@action public async getAllDocList(params: {
		docid?: number
		endDate?: string
		pageNo: number
		pageSize: number
		startDate?: string
		timeSection?: string
	}) {
		const { data: res } = await OrderApi.AllDocOrderList(pruneEmpty(params))
		this.getOrderCard(res)
		runInAction(() => {
			const { dataList } = res
			const { list, startRow } = dataList

			const nextList = handleList(list, startRow)

			this.orderDataSource = {
				...{}, ...dataList, ...{
					list: nextList
				}
			}
			this.orderList = nextList
		})
		return res
	}

	@action public async getDoctorSuggest(params: { docName: string }) {
		const { data: res } = await OrderApi.SearchDoctor(params)
		runInAction(() => {
			this.completeData = res.map(
				(c: { id: number; docName: string } | null) => {
					const obj = c && {
						value: c.id,
						text: c.docName
					}
					return obj
				}
			)
		})
		return res
	}

	@action public async getDocOrderDetail(params: IDocOrderQuery) {
		const { data: res } = await OrderApi.DocOrderDetail(pruneEmpty(params))
		res.userSumFlow = res.sumFlow
		delete res.sumFlow
		this.getOrderCard(res)

		const { dataList } = res
		const { list, startRow } = dataList
		const nextList = list
		const nextDataList = {
			...{}, ...dataList, ...{
				list: nextList
			}
		}

		this.orderDataSource = {
			...{}, ...res, ...{
				dataList: nextDataList
			}
		}

		this.orderList = nextList
	}
	@action public async getDocAddress(params: { docid: number }) {
		const { data: res } = await OrderApi.GetDoctorAddress(params)
		runInAction(() => {
			this.docAddress = res
		})
		return res
	}
	@action public getMakeUpList() {
		this.makeUpTableList = []
	}
	@action public getMakeUpRecord() {
		this.MakeUpRecord = []
	}

	@action public async getStatus() {
		const { data: res } = await OrderApi.GetAllOrderStatus()
		this.allOrderStatus = res
	}
	@action public async getAllOrderType() {
		const { data: res } = await OrderApi.GetAllOrderType()
		this.allOrderType = res
	}
	@action public async getFansName(params: {
		docid: number
		userName: string
	}) {
		const { data: res } = await OrderApi.SearchDocFans(params)
		this.fansNameDataSource = res
	}

	@action public async getOrderDetail(params: { orderId: string }) {
		try {
			const { data: res } = await OrderApi.OrderDetail(params)
			this.orderData = preHandlerOrderDetail(res)
		} catch (error) { }
	}

	@action public async updateOrder(params: IUpdateOrderQuery) {
		try {
			await OrderApi.UpdateOrder(params)
			return Promise.resolve('success')
		} catch (error) {
			return Promise.reject(error)
		}
	}

	@action public async getOrderSettleData(params: IOrderQuery) {
		const { data: res } = await FinanceApi.GetOrderSettleData(
			pruneEmpty(params)
		)
		const { dataList } = res
		const { list, startRow } = dataList
		const nextList = handleList(list, startRow)

		const nextDataList = {
			...{}, ...dataList, ...{
				list: nextList
			}
		}

		this.orderSettleData = nextDataList
		this.getOrderCard(res)
	}
	@action public async getDocOrderSettleData(params: IDocOrderQuery) {
		const { data: res } = await FinanceApi.GetDocSettleData(pruneEmpty(params))
		const { dataList } = res
		const { list, startRow } = dataList
		const nextList = handleList(list, startRow)

		const nextDataList = {
			...{}, ...dataList, ...{
				list: nextList
			}
		}

		this.docOrderSettleData = nextDataList
		this.getOrderCard(res)
	}
	@action public async getSettleDetail(params: { orderId: string }) {
		const { data: res } = await FinanceApi.GetSettleOrderDetail(params)
		this.orderData = res
	}
	@action public async confirmOrder(params: { orderId: string }) {
		try {
			await OrderApi.ConfirmOrder(params)
			return Promise.resolve('success')
		} catch (error) {
			return Promise.reject(error)
		}
	}
	constructor() {
		this.orderData = {}
		this.orderList = []
		this.orderCardData = {}
		this.makeUpTableList = []
		this.MakeUpRecord = []
		this.completeData = []
		this.docAddress = []
		this.allOrderStatus = []
	}
}

export default OrderStore
