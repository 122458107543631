import { message } from 'antd'
import axios, {
	AxiosError,
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse
} from 'axios'
import { createBrowserHistory } from 'history'
import createHistory from 'history/createHashHistory'
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import { updateRoutePath } from '../../common/utils/publicUtils'

interface resData {
	code: number
	data: object | Array<object>
	message: string
}
class Service {
	public $http: AxiosInstance
	constructor() {
		this.$http = this.initHttp()
		this.useInterceptor()
	}

	private initHttp(): AxiosInstance {
		return axios.create({
			baseURL: process.env.REACT_APP_BASE_URL,
			headers: {
				'Content-Type': 'application/json'
			},
			timeout: 10000
		})
	}

	private useInterceptor() {
		// 请求拦截器
		this.$http.interceptors.request.use(
			(config: AxiosRequestConfig) => {
				const token = window.sessionStorage.getItem('token')
				if (token) {
					config.headers['token'] = token
				}
				return config
			},
			(error: AxiosError) => {
				console.log(error)
				message.info(error.message)
				return Promise.reject(error)
			}
		)
		// 响应拦截器
		this.$http.interceptors.response.use(
			(response: AxiosResponse) => {
				const { status, data, statusText, headers } = response
				console.log('res', response)
				if (data.code !== 200) {
					const { code } = data
					if (code === 401) {
						window.location.href = updateRoutePath('/')
						return Promise.reject({
							code: 401,
							msg: ''
						})
					}

					if (code === 400) {
						message.error(data.message)
						return Promise.resolve(data)
					}

					return Promise.reject({
						code: 500,
						msg: 'error'
					})
				}
				return Promise.resolve(data)
				// return data
			},
			(error: AxiosError) => {
				message.info(error.message)
				return Promise.reject({
					code: (error.response && error.response.status) || 500,
					msg: error.message
				})
			}
		)
	}
}

export default Service
