import { action, computed, observable, runInAction } from 'mobx'
import ReplyApi, { IReplyDetail, IReplyQuery, IReplyBase, IReplyItem, IAnnounceReply } from '../apis/reply'
import OrderApi from '../apis/Order'
import { IDocQuery } from '../common/interface/apiQuery'
import { pruneEmpty, randomString } from '../common/utils/publicUtils'
import { handleList } from '../common/utils/handler'

class ReplyStore {
  @observable public list: any = {}

  @action public async getList(params: IReplyQuery) {
    const { data: res } = await ReplyApi.list(pruneEmpty(params))
    runInAction(() => {
      const { list, startRow } = res
      const nextList = handleList(list, startRow)

      const timestamp: number = Date.now()
      this.list = {...{}, ...res, ...{
        list: nextList,
        timestamp
      }}
    })
    return res
  }

  @action public async getAnnounceList(params: IReplyQuery) {
    const { data: res } = await ReplyApi.announceList(pruneEmpty(params))
    runInAction(() => {
      const { list, startRow } = res
      const nextList = handleList(list, startRow)

      const timestamp: number = Date.now()
      this.list = {...{}, ...res, ...{
        list: nextList,
        timestamp
      }}
    })
    return res
  }

  @action public setList(list: any[]) {
    const { startRow} = this.list
    const nextList = handleList(list, startRow)
    
    const timestamp: number = Date.now()
    this.list = {...{}, ...this.list, ...{
      list: nextList,
      timestamp
    }}
    return this.list
  }

  @action public async create(params: IReplyItem) {
    const { data: res } = await ReplyApi.create(params)
    return res
  }

  @action public async hide(params: any) {
    const { data } = await ReplyApi.hide(params)
    return data
  }

  @action public async annouceCreate(params: IReplyItem) {
    const { data: res } = await ReplyApi.annouceCreate(params)
    return res
  }
}

export default ReplyStore