import { action, computed, observable, runInAction } from 'mobx'
import AnnounceApi, { IAnnounceDetail } from '../apis/announce'

class AnnounceStore {
  @observable public Detail: any = {}
  @observable public previewInfo: any = {}

  @action public async create(params: IAnnounceDetail) {
    const { data: res } = await AnnounceApi.create(params)
    return res
  }

  @action public async getDetail(params: { noticeId: string | number, docId: string | number, orderBy?: string}) {
    const { data: res } = await AnnounceApi.detail(params)
    const { content, scopeFans } = res

    const nextData = {...{}, ...res, ...{
      content: content || '',
      scopeFans: scopeFans || []
    }}
    this.Detail = nextData
    
    return res
  }

  @action public async top(params: {docId: any, noticeId: any, top: string | number}) {
    const { data: res } = await AnnounceApi.top(params)
    return res
  }

  @action public async delete(params: {docId: any, noticeId: any}) {
    const { data: res } = await AnnounceApi.delete(params)
    return res
  }

  @action public async setVisible(params: {docId: any, noticeId: any, publicScope: any, fansList?: any[]}) {
    const { data: res } = await AnnounceApi.setVisible(params)
    return res
  }

  @action public async getAfficheInfo (params: IAnnounceDetail) {
    const { data } = await AnnounceApi.getAfficheInfo(params)
    runInAction(() => {
      this.previewInfo = data
    })
    return data
  }

  @action public async hide(params: any) {
    const { data } = await AnnounceApi.hide(params)
    return data
  }
}

export default AnnounceStore