import { action, computed, observable, runInAction } from 'mobx'
import CustomChargingApi, { ICustomChargingQuery, ICustomChargingDetail } from '../apis/customCharging'
import OrderApi from '../apis/Order'
import { IDocQuery } from '../common/interface/apiQuery'
import { pruneEmpty, randomString } from '../common/utils/publicUtils'
import { handleList } from '../common/utils/handler'

class CustomChargingStore {
  @observable public docList: any = {}
  @observable public list: any = {}
  @observable public getDoctorSuggestData: any = []
  @observable public detail: any = {}
  @observable public previewInfo: any = {}

  @action public async getDocList(params: IDocQuery) {
    const { data: res } = await CustomChargingApi.docList(pruneEmpty(params))
    runInAction(() => {
      const { list, startRow } = res
      const nextList = handleList(list, startRow)

      const timestamp: number = Date.now()
      this.docList = {...{}, ...res, ...{
        list: nextList,
        timestamp
      }}
    })
    return res
  }

  @action public async getList(params: ICustomChargingQuery) {
    const { data: res } = await CustomChargingApi.list(pruneEmpty(params))
    runInAction(() => {
      const { list, startRow } = res
      const nextList = handleList(list, startRow)

      const timestamp: number = Date.now()
      this.list = {...{}, ...res, ...{
        list: nextList,
        timestamp
      }}
    })
    return res
  }

  @action public async getDoctorSuggest(params: { docName: string }) {
    const { data: res } = await OrderApi.SearchDoctor(params)
    runInAction(() => {
        this.getDoctorSuggestData = res.map(
            (item: { id: number; docName: string } | null) => {
                const obj = item && {
                    value: item.id,
                    text: item.docName
                }
                return obj
            }
        )
    })
    return res
  }

  @action public async create(params: ICustomChargingDetail) {
    const { data: res } = await CustomChargingApi.create(params)
    return res
  }

  @action public async delete(params: any) {
    const { data } = await CustomChargingApi.delete(params)
    return data
  }

  @action public async getDetail(params: any) {
    const { data } = await CustomChargingApi.detail(params)
    runInAction(() => {
      this.detail = data
    })
    return data
  }

  @action public async preview(params: any) {
    const { data } = await CustomChargingApi.preview(params)
    runInAction(() => {
      this.previewInfo = data
    })
    return data
  }
}

export default CustomChargingStore