import { message } from 'antd'
import { action, computed, observable, runInAction } from 'mobx'
import UserApi from '../apis/User'

interface Feature {
	name: string
	id: string
	active: boolean
}
interface IRoleInfo {
	roleType: string
	roleName: string
	privileges: number[]
	id: string
	scope: 'ALL' | 'PART' | ''
}

class RoleAdminStore {
	@observable public modalVisible: boolean = false
	@observable public roleInfo: IRoleInfo = {
		roleType: '',
		roleName: '',
		privileges: [],
		id: '',
		scope: ''
	}
	@observable public roleData: any = {}
	@observable public roleId: string = ''
	@observable public roleSort: { id: string; name: string }[] = []
	@observable public searchInfo: { roleType: string; roleName: string } = {
		roleName: '',
		roleType: ''
	}
	@observable public features: Feature[] = []
	@observable public chioceFeatures: Feature[] = []

	@computed public get total() {
		return this.roleData.total || 0
	}

	@computed public get roleList() {
		const { list } = this.roleData
		return list || []
	}
	@action public modalClose() {
		this.modalVisible = false
	}
	@action public modalShow() {
		this.modalVisible = true
	}

	@action public updateSearchInfo = (
		field: 'roleType' | 'roleName',
		value: any
	) => {
		this.searchInfo[field] = value
	}

	@action public checkFeatures(item: Feature, index: number) {
		const { id, active } = item
		if (!!active) {
			const choiceIndex = this.chioceFeatures.findIndex(c => c.id === id)
			const featureIndex = this.features.findIndex(x => x.id === id)
			this.chioceFeatures.splice(choiceIndex, 1)
			this.features[featureIndex].active = false
		} else {
			item.active = true
			this.chioceFeatures.push(item)
			this.features[index].active = true
		}
	}
	@action public getRoleSort = async () => {
		const { data } = await UserApi.RoleSort()
		runInAction(() => {
			this.roleSort = data
		})
	}

	@action public getData = async (
		pageNo: number = 1,
		pageSize: number = 10
	) => {
		const { data } = await UserApi.GetRoleList(
			Object.assign(
				{},
				{
					pageNo,
					pageSize
				},
				this.searchInfo
			)
		)
		this.roleData = data
	}

	@action public updateRoleId = (id: string) => {
		runInAction(() => {
			this.roleId = id
		})
	}

	@action public getRoleInfo = async () => {
		const { data } = await UserApi.RoleInfo({ roleId: this.roleId })
		await this.getFeatures(data.roleType)
		data.privileges.forEach((el: any) => {
			const index = this.features.findIndex(c => el.id === c.id)
			if (index !== -1) {
				this.features[index].active = true
			}
		})
		this.chioceFeatures = data.privileges.map((c: any) =>
			Object.assign({}, c, { active: true })
		)
		this.roleInfo = data
	}
	@action public updateRoleInfo = (
		field: 'roleType' | 'roleName' | 'privileges' | 'scope',
		value: any
	) => {
		this.roleInfo[field] = value
		if (field === 'roleType') {
			this.getFeatures(value)
			this.chioceFeatures = []
		}
	}
	@action public setRoleInfo = () => {
		this.chioceFeatures = []
	}
	@action public getFeatures = async (roleTypeId: string) => {
		const { data: res } = await UserApi.featureList({ roleTypeId })
		this.features = res.map((c: any) => {
			return Object.assign(c, { modulerName: c.name }, { active: false })
		})
	}
	@action public saveInfo = async (history: any) => {
		try {
			const { roleId } = this
			await UserApi.UpdateRole(
				Object.assign({}, this.roleInfo, {
					privileges: this.chioceFeatures.map(c => c.id)
				})
			)
			if (roleId) {
				message.success('保存成功')
			} else {
				message.success('创建成功')
			}
			history.goBack()
		} catch (error) {}
	}

	@action public freed = () => {
		this.roleId = ''
		Object.keys(this.roleInfo).forEach(
			key => ((this.roleInfo as { [key: string]: any })[key] = '')
		)
	}
}
export default RoleAdminStore
