import { AxiosPromise } from 'axios'
import { IDocOrderQuery } from '../common/interface'
import {
	IAddSettingQuery,
	IAddSettingQueryV2,
	IConfigQuery,
	IDocMakeupQuery,
	IMakeupOrderQuery,
	IOrderQuery,
	IWithdrawQuery,
	IWithdrawSettleQuery
} from '../common/interface/apiQuery'
import Service from '../common/service'

class Finance extends Service {
	// 提现审核列表
	public WithdrawReviewList<T>(params: IWithdrawQuery): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/cash/withdraw/mainList', params)
	}

	// 提现结算列表
	public WithDrawSettleList<T>(params: IWithdrawSettleQuery): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/cash/withdraw/settleList', params)
	}

	// 提现审核详情
	public WithdrawReviewDetail<T>(params: { id: string }): AxiosPromise<T> {
		return this.$http.get(`/yimai-admin/cash/withdraw/detail/${params.id}`)
	}
	// 提现审核通过
	public ByWithdrawReview<T>(params: { id: string }): AxiosPromise<T> {
		return this.$http.post(`/yimai-admin/cash/withdraw/confirm/${params.id}`)
	}
	// 批量结算
	public BatchSettle<T>(params: { ids: any[] }): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/cash/withdraw/batch/detail', params)
	}

	// 获取补单审核列表
	public GetMakeupOrderList<T>(params: IMakeupOrderQuery): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/order/finacial/mainList', params)
	}
	// 获取补单订单详情
	public GetMakeupOrderDetail<T>(params: { orderId: string }): AxiosPromise<T> {
		return this.$http.get(
			`/yimai-admin/order/finacial/detail/${params.orderId}`
		)
	}
	// 补单批量审核查询
	public MakeupOrderBatchSearch<T>(params: {
		checkType: string
		endTime: string
		startTime: string
	}): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/order/finacial/search', params)
	}

	// 确认补单审核通过
	public ConfirmByReview<T>(params: {
		orderId: string
		doctorPercentage: string | number
		hospitalPercentage: string | number
		platformPercentage: string | number
		pictures: any[]
	}): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/order/finacial/single/confirm', params)
	}

	/**
	 * 批量补单审核通过
	 * @param params 
	 * @returns 
	 */
	public ConfirmByReviewBatch<T>(params: {
		orderIds: Array<string>
		doctorPercentage: string | number
		hospitalPercentage: string | number
		platformPercentage: string | number
	}): AxiosPromise<T> {
		return this.$http.post(`/yimai-admin/order/finacial/batch/confirm`, params)
	}

	// 订单驳回
	public RejectMakeupOrder<T>(params: {
		orderId: string
		reason: string
	}): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/order/finacial/single/reject', params)
	}

	// 订单结算
	public GetOrderSettleData<T>(params: IOrderQuery): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/order/settle/mainList', params)
	}
	// 单个医生订单结算
	public GetDocSettleData<T>(params: IDocOrderQuery): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/order/settle/mainDetail', params)
	}
	// 结算订单详情
	public GetSettleOrderDetail<T>(params: { orderId: string }): AxiosPromise<T> {
		return this.$http.get(`/yimai-admin/order/settle/detail/${params.orderId}`)
	}
	// 获取配置列表
	public getSettings<T>(params: IConfigQuery): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/account/settings/list', params)
	}
	// 搜索医生配置
	public getDocSettings<T>(params: { docid: string }): AxiosPromise<T> {
		return this.$http.get(
			`/yimai-admin/account/settings/search/${params.docid}`
		)
	}
	// 添加医生服务费率配置
	public addDocTaxScale<T>(params: {
		docid: string
		taxScale: number
	}): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/account/settings/addTaxScale', params)
	}
	// 获取医生配置详情
	public getDocSettingDetail<T>(params: { docid: string }): AxiosPromise<T> {
		return this.$http.get(
			`/yimai-admin/account/settings/detail/${params.docid}`
		)
	}
	// 获取已配置医生全局配置
	public getDocGlobalSetting<T>(params: { docid: string }): AxiosPromise<T> {
		return this.$http.get(
			`/yimai-admin/account/settings/getTaxScale/${params.docid}`
		)
	}
	// 添加配置
	public mergeSettings<T>(params: IAddSettingQuery): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/account/settings/merge', params)
	}

	/**
	 * 添加配置 v2
	 * @param params 
	 * @returns 
	 */
	public mergeSettingsv2<T>(params: IAddSettingQueryV2): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/account/settings/v2/merge', params)
	}

	// 查看单个医生补单列表
	public docMakeupList<T>(params: IDocMakeupQuery): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/order/finacial/mainDetail', params)
	}
	// 第三方服务费率列表
	public thirdpartFeeList<T>(): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/cash/withdraw/thirdPart/fee')
	}

	// 确认打款
	public confirmPay<T>(params: {
		id: string
		providerId: string
		pictures: string[]
	}): AxiosPromise<T> {
		return this.$http.post('/yimai-admin/cash/withdraw/payConfirm', params)
	}

	/**
	 * 获取医生配置医院
	 * @param params 
	 */
	public getDoctorAddress<T>(params: { docid: string }): AxiosPromise<T> {
		return this.$http.get(`/yimai-admin/common/suggest/getDoctorAddress/${params.docid}`)
	}
}

export default new Finance()
