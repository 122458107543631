import { action, computed, observable, runInAction } from 'mobx'
import FansApi, { IFansQuery, IFansDetail } from '../apis/fans'
import { IDocQuery } from '../common/interface/apiQuery'
import { pruneEmpty, randomString } from '../common/utils/publicUtils'
import { handleList } from '../common/utils/handler'

class FansStore {
  @observable public list: any = {}
  @observable public tags: any = {}

  @action public async getList(params: IFansQuery) {
    const { data: res } = await FansApi.list(pruneEmpty(params))
    runInAction(() => {
      const { list, startRow } = res
      const nextList = handleList(list, startRow)

      const timestamp: number = Date.now()
      this.list = {...{}, ...res, ...{
        list: nextList,
        timestamp
      }}
    })
    return res
  }

  @action public async getTags(params: { docId: string | number}) {
    const { data: res } = await FansApi.tags(params)
    runInAction(() => {
      this.tags = res
    })
    return res
  }

  @action public setTagList(tagList: any[]) {
    this.tags = tagList
  }
}

export default FansStore