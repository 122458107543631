import { LocaleProvider } from 'antd'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import { Provider } from 'mobx-react'
import { RouterStore } from 'mobx-react-router'
import moment from 'moment'
import 'moment/locale/zh-cn'
import 'normalize.css'
import React from 'react'
import ReactDOM from 'react-dom'
import Root from './containers/Root/Root'
import {
	ConfigDoctor,
	Content,
	CustomCharging,
	Doctor,
	Fans,
	Finance,
	GlobalModel,
	GoodDoctor,
	LoginStore,
	MakeupStore,
	MHeader,
	OrderPrivate,
	OrderStore,
	RefundStore,
	Reply,
	RoleAdminStore,
	UserManage,
	FansCashout,
	Announce,
	DoctorSettle,
	DoctorCashDeposit
} from './store'
moment.locale('zh-cn')

const RootStore = {
	global: new GlobalModel(),
	router: new RouterStore(),
	auth: new LoginStore(),
	header: new MHeader(),
	order: new OrderStore(),
	finance: new Finance(),
	makeup: new MakeupStore(),
	manage: new UserManage(),
	refund: new RefundStore(),
	privateOrder: new OrderPrivate(),
	customCharging: new CustomCharging(),
	content: new Content(),
	roleStore: new RoleAdminStore(),
	reply: new Reply(),
	fans: new Fans(),
	doctorInfo: new Doctor(),
	configDoctor: new ConfigDoctor(),
	goodDoctor: new GoodDoctor(),
	fansCashout: new FansCashout,
	announce: new Announce(),
	doctorSettle: new DoctorSettle(),
	doctorCashDeposit: new DoctorCashDeposit()
}

ReactDOM.render(
	<Provider {...RootStore}>
		<LocaleProvider locale={zh_CN}>
			<Root />
		</LocaleProvider>
	</Provider>,
	document.getElementById('root') as HTMLElement
)
