import { AxiosPromise } from 'axios'
import { ListBase, IDocQuery } from '../common/interface/apiQuery'
import Service from '../common/service'
import { hasMock } from '../common/utils/publicUtils'
import Config from '../common/config'
const { mockApi } = Config.app
const mockApiPrefix = `${mockApi}/reply`

export interface IReplyQuery extends ListBase {
	itemId?: string | number
	noticeId?: string | number
	docId?: string | number
}

export interface IReplyBase {
	itemId: string | number
	bundleId: string
	fromId: string | number
	toId: string | number
	content: string
	type: string | number
	fromName: string
	bundleReply: IReplyBase[]
}

export interface IReplyDetail extends IReplyBase {
	createTime: string
	vote: boolean
	headimgurl: string
	voteCount: string | number
}

export interface IReplyItem {
	itemId?: string | number
	noticeId?: string | number
	content: string
	fromId: string | number
	toId: string | number
	type: string | number
}

export interface IAnnounceReply {
	noticeId: string | number
	content: string
	fromId: string | number
	toId: string | number
	type?: string | number
}

class ReplyApi extends Service {
	/**
	 * 案例评论
	 * @param params 
	 */
	public list<T>(params: IReplyQuery): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApiPrefix}/list.json`)
		} else {
			return this.$http.post('yimai-admin/comment/case/list', params)
		}
	}

	/**
	 * 公告评论
	 * @param params 
	 */
	public announceList<T>(params: IReplyQuery): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApiPrefix}/list.json`)
		} else {
			return this.$http.post('yimai-admin/affiche/reply/list', params)
		}
	}

	/**
	 * @param params
	 */
	public create<T>(params: IReplyItem): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(`${mockApi}/success.json`)
		} else {
			return this.$http.post('yimai-admin/comment/case/reply', params)
		}
	}

	public hide<T>(params: any): AxiosPromise<T> {
		return this.$http.post('yimai-admin/comment/case/hide', params)
	}

	/**
	 * 公告回复
	 * @param params 
	 */
	public annouceCreate<T>(params: any): AxiosPromise<T> {
		if (hasMock(false)) {
			return this.$http.get(``)
		} else {
			return this.$http.post('yimai-admin/affiche/reply', params)
		}
	}
}

export default new ReplyApi()