import { AxiosPromise } from 'axios'
import Config from '../common/config'
import { ListBase } from '../common/interface/apiQuery'
import Service from '../common/service'
import { hasMock } from '../common/utils/publicUtils'
import { settleCalcWay } from '../common/enums'
const { mockApi } = Config.app
const mockApiPrefix = `${mockApi}/doctorSettle`

export interface IDoctorSettleBaseQuery {
  docId?: string | number
  year?: string | number
  month?: string | number
  day?: string | number
  settlePeriodType?: string | number
}

export interface IDoctorSettleQuery extends IDoctorSettleBaseQuery, ListBase {
}

export interface IDoctorSettleDetailQuery {
  id: number | string
}

export interface IDoctorSettleListQuery extends ListBase {
  id: number | string
  settleTimeType?: number | string
}

export interface ISetDoctorIncome {
  id: number | string
  doctorIncome?: number | string
  doctorRefund?: number | string
}

export interface ISettingListQuery extends ListBase {
  docid?: string | number
  queryText?: string
}
export interface IDoctorSettleDetail {
  id?: string | number
  drId?: string | number
  drName?: string
  opAmountSum?: string | number
  opPayAmountSum?: string | number
  obAmountSum?: string | number
  obPayAmountSum?: string | number
  confirmedOpSettleSum?: string | number
  confirmedObSettleSum?: string | number
  opRefundAmountSum?: string | number
  obRefundAmountSum?: string | number
  confirmedOpRefundSum?: string | number
  confirmedObRefundSum?: string | number
  confirmedSettleAmountSum?: string | number
  portraitFeeReduce?: string | number
  operatingCost?: string | number
  otherCost?: string | number
  settleMonth?: string | number
  settleYear?: string | number
  settleDay?: string | number
  settleMoney?: string | number
  avaliableMoney?: string | number
  settleStatus?: string | number
}

export interface IUpdateReportEntity {
  id: string | number
  portraitFeeReduce?: string | number
  operatingCost?: string | number
  otherCost?: string | number
}

export interface IComputeIncomeEntity {
  id: string | number
  settleWay: settleCalcWay | undefined
}

export interface IWaitingDoctorSettleQuery extends ListBase {
  docId?: string | number
  year?: string | number | undefined
  month?: string | number | undefined
  day?: string | number | undefined
}

class DoctorSettleApi extends Service {
  /**
   * 列表
   */
  public list<R>(params: IDoctorSettleQuery): AxiosPromise<R> {
    if (hasMock(false)) {
      return this.$http.get(`${mockApiPrefix}/list.json`)
    }

    return this.$http.post(`yimai-admin/settle/order/list`, params)
  }

  /**
   * 生成指定医生结算单
   */
  public build<R>(params: IDoctorSettleBaseQuery): AxiosPromise<R> {
    if (hasMock(false)) {
      return this.$http.get(`${mockApi}/success.json`)
    }

    return this.$http.post(`yimai-admin/settle/order/createSettle`, params)
  }

  /**
   * 生成结算单
   * @param params 
   * @returns 
   */
  public buildAll<R>(params: IDoctorSettleBaseQuery): AxiosPromise<R> {
    return this.$http.post(`yimai-admin/settle/order/createSettleAll`, params)
  }

  /**
   * 创建结算单
   * @param params 
   * @returns 
   */
  public initAll<R>(params: IDoctorSettleBaseQuery): AxiosPromise<R> {
    return this.$http.post(`yimai-admin/settle/order/initSettleAll`, params)
  }

  /**
   * 结算单详情
   * @param params 
   * @returns 
   */
  public detail<R>(params: IDoctorSettleDetailQuery): AxiosPromise<R> {
    if (hasMock(false)) {
      return this.$http.get(`${mockApiPrefix}/detail.json`)
    }

    return this.$http.get(`yimai-admin/settle/order/detail?id=${params.id}`)
  }

  /**
   * 结算单明细列表
   * @param params 
   * @returns 
   */
  public settlementList<R>(params: IDoctorSettleListQuery): AxiosPromise<R> {
    if (hasMock(false)) {
      return this.$http.get(`${mockApiPrefix}/list.json`)
    }

    return this.$http.post(`yimai-admin/settle/order/detailList`, params)
  }

  /**
   * 设置医生收入和医生退款
   * @param params 
   * @returns 
   */
  public setDoctorIncome<R>(params: ISetDoctorIncome): AxiosPromise<R> {
    if (hasMock(false)) {
      return this.$http.get(`${mockApi}/success.json`)
    }

    return this.$http.post(`yimai-admin/settle/operator/updIncome`, params)
  }

  /**
   * 结算配置列表
   * @param params 
   * @returns 
   */
  public settingList<R>(params: ISettingListQuery): AxiosPromise<R> {
    return this.$http.post(`yimai-admin/account/settings/v2/list`, params)
  }

  /**
   * 结算配置详情
   * @param params 
   * @returns 
   */
  public settingDetail<R>(params: any): AxiosPromise<R> {
    return this.$http.get(`yimai-admin/account/settings/v2/detail/${params.docid}`)
  }

  /**
   * 添加结算扣除项
   * @param params 
   * @returns 
   */
  public updateReport<R>(params: IUpdateReportEntity): AxiosPromise<R> {
    return this.$http.post(`yimai-admin/settle/operator/updReport`, params)
  }

  /**
   * 指定公式计算订单收入
   * @param params 
   * @returns 
   */
  public computeIncome<R>(params: IComputeIncomeEntity): AxiosPromise<R> {
    return this.$http.post(`yimai-admin/settle/operator/computeIncome`, params)
  }

  /**
   * 确认医生结算
   * @param params 
   * @returns 
   */
  public complete<R>(params: any): AxiosPromise<R> {
    return this.$http.post(`yimai-admin/settle/operator/complete?settleId=${params.id}`)
  }

  public orderComplete<R>(params: any): AxiosPromise<R> {
    return this.$http.post(`yimai-admin/settle/operator/orderComplete?id=${params.id}`)
  }

  /**
   * 医生收入推到可提现账户
   * @param params 
   * @returns 
   */
  public push<R>(params: any): AxiosPromise<R> {
    return this.$http.post(`yimai-admin/settle/operator/push?settleId=${params.id}`)
  }

  public settleDelayConfirm<R>(params: any): AxiosPromise<R> {
    return this.$http.post(`yimai-admin/settle/order/delaytrans/confirm`, params);
  }

  public waitingDoctorSettleList<R>(params: any): AxiosPromise<R> {
    return this.$http.post(`yimai-admin/settle/order/waiting/doctor/confirm/list`, params);
  }
}

export default new DoctorSettleApi()