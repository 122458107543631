import { action, computed, observable, runInAction, toJS } from 'mobx'
import MakeupApi from '../apis/Makeup'
import OrderApi from '../apis/Order'
import { IAddMakeupQuery, IMakeupQuery } from '../common/interface/apiQuery'
import { pruneEmpty, randomString } from '../common/utils/publicUtils'
import { preHandlerOrderDetail, handleList } from '../common/utils/handler'

class MakeupStore {
	@observable public makeupData: any = {}
	@observable public makeupRecordData: any = {}
	@observable public makeupDetailList: any[] = []
	@observable public allDocData: any[] = []
	@observable public makeupList: any[] = []
	@observable public orderStatusList: any[] = []
	@observable public orderTypeList: any[] = []
	@observable public hosAddresses: any[] = []
	@observable public fansUserList: any[] = []
	@observable public makeupDetailData: any = {}
	@observable public distType: string = ''
	@observable public selectTimeList: any[] = []

	@computed public get total() {
		return this.makeupData.total
	}

	@computed public get recordTotal() {
		return this.makeupRecordData.total
	}

	@action public async getDocSugget(params: { docName: string }) {
		const { data: res } = await OrderApi.SearchDoctor(params)
		this.allDocData = res
	}

	@action public async getMakeupList(params: {
		docid?: number
		pageNo: number
		pageSize: number
	}) {
		const { data: res } = await MakeupApi.getAllMakeupOrder(params)
		const { list, startRow } = res
		const nextList = handleList(list, startRow)

		this.makeupData = {
			...{}, ...res, ...{
				list: nextList
			}
		}

		this.makeupList = nextList.map((item: any) => {
			return Object.assign(
				{
					key: randomString()
				},
				item
			)
		})
	}
	@action public async getFansList(params: {
		docid: number
		userName?: string
		fansNo?: string
	}) {
		const { data: res } = await OrderApi.SearchDocFans(params)
		this.fansUserList = res.map((item: any) =>
			Object.assign({ key: randomString() }, item)
		)
	}
	@action public async getOrderStatus() {
		const { data: res } = await MakeupApi.getOrderStatus()
		this.orderStatusList = res.map((item: any) => {
			return Object.assign({ key: randomString() }, item)
		})
	}
	@action public async getOrderType() {
		const { data: res } = await MakeupApi.getOrderTypes()
		this.orderTypeList = res.map((item: any) => {
			return Object.assign({ key: randomString() }, item)
		})
	}

	@action public async getDocHosAddress(params: { docid: number }) {
		const { data: res } = await OrderApi.GetDoctorAddress(params)
		runInAction(() => {
			this.hosAddresses = res.map((item: any) =>
				Object.assign({ key: randomString() }, item)
			)
		})
	}
	@action public async getDocMakeupRecord(params: IMakeupQuery) {
		const { data: res } = await MakeupApi.getDocMakeupOrder(pruneEmpty(params))

		const { list, startRow } = res
		const nextList = handleList(list, startRow)

		this.makeupRecordData = {
			...{}, ...res, ...{
				list: nextList
			}
		}
		this.makeupDetailList = nextList.map((item: any) => {
			return Object.assign({ key: randomString() }, item)
		})
	}
	@action public async getOrderDetail(params: { orderId: string }) {
		const { data: res } = await MakeupApi.getMakeupDetail(params)
		this.makeupDetailData = res
	}

	@action public async subMakeupOrder(params: IAddMakeupQuery) {
		try {
			const data = await MakeupApi.SupplementAdd(pruneEmpty(params))

			return Promise.resolve(data)
		} catch (error) {
			return Promise.reject(error)
		}
	}

	@action public async orderJudge(params: {
		docid: string
		orderType: string
	}) {
		const { data: res } = await MakeupApi.orderJudge(params)
		this.distType = res.isDistinguishFansSource
	}
	@action public async selectTime(params: {
		docid: string
	}) {
		const { data: res } = await MakeupApi.selectTime(params)
		this.selectTimeList = res
	}
}

export default MakeupStore
