import { action, computed, observable, runInAction } from 'mobx'
import ContentApi, { IContentQuery, IContentDetail, IContentPhase } from '../apis/content'
import OrderApi from '../apis/Order'
import { IDocQuery } from '../common/interface/apiQuery'
import { pruneEmpty, randomString } from '../common/utils/publicUtils'
import { handleList } from '../common/utils/handler'

class ContentStore {
  @observable public docList: any = {}
  @observable public list: any = {}
  @observable public getDoctorSuggestData: any = []
  @observable public Detail: any = {}
  @observable public Item: any = {}
  @observable public tagList: any[] = []
  @observable public previewInfo: any = {}
  @observable public doctorTagList: any[] = []
  @observable public shareInfo: any = {}

  @action public async getDocList(params: IDocQuery) {
    const { data: res } = await ContentApi.docList(pruneEmpty(params))
    runInAction(() => {
      const { list, startRow } = res
      const nextList = handleList(list, startRow)

      const timestamp: number = Date.now()
      this.docList = {...{}, ...res, ...{
        list: nextList,
        timestamp
      }}
    })
    return res
  }

  @action public async getList(params: IContentQuery) {
    const { data: res } = await ContentApi.list(pruneEmpty(params))
    runInAction(() => {
      const { list, startRow } = res
      const nextList = handleList(list, startRow)

      const timestamp: number = Date.now()
      this.list = {...{}, ...res, ...{
        list: nextList,
        timestamp
      }}
    })
    return res
  }

  @action public async getDoctorSuggest(params: { docName: string }) {
    const { data: res } = await OrderApi.SearchDoctor(params)
    runInAction(() => {
        this.getDoctorSuggestData = res.map(
            (item: { id: number; docName: string } | null) => {
                const obj = item && {
                    value: item.id,
                    text: item.docName
                }
                return obj
            }
        )
    })
    return res
  }

  @action public async create(params: IContentDetail) {
    const { data: res } = await ContentApi.create(params)
    return res
  }

  @action public async getDetail(params: { id: string | number, docId: string | number, orderBy: string}) {
    const { data: res } = await ContentApi.detail(params)
    const { beforeDescription, scopeFans } = res
    const nextData = {...{}, ...res, ...{
      beforeDescription: beforeDescription || '',
      scopeFans: scopeFans || []
    }}
    this.Detail = nextData
    return res
  }

  @action public async getItem(params: { id: string | number, caseId?: string | number}) {
    const { data: res } = await ContentApi.item(params)
    this.Item = res
    return res
  }

  @action public async top(params: { id: string | number, docId?: string | number }) {
    const { data: res } = await ContentApi.top(params)
    return res
  }

  @action public async delete(params: any) {
    const { data: res } = await ContentApi.delete(params)
    return res
  }

  @action public async getTagList(params: any) {
    const { data } = await ContentApi.tagList(params)
    runInAction(() => {
      this.tagList = data
    })
    return data
  }

  @action public async setVisible(params: any) {
    const { data } = await ContentApi.setVisible(params)
    return data
  }

  @action public setTagList(tagList: any[]) {
    this.tagList = tagList
   }

  @action public async deletePhase(params: any) {
    const { data: res } = await ContentApi.deletePhase(params)
    return res
  }

  @action public async preview(params: IContentDetail) {
    const { data } = await ContentApi.preview(params)
    runInAction(() => {
      this.previewInfo = data
    })
    return data
  }

  @action public async phasePreview(params: IContentDetail) {
    const { data } = await ContentApi.phasePreview(params)
    runInAction(() => {
      this.previewInfo = data
    })
    return data
  }

  @action public async createPhase(params: any) {
    const { data } = await ContentApi.createPhase(params)
    return data
  }

  @action public async getDoctorTagList(params: any) {
    const { data } = await ContentApi.getDoctorTagList(params)
    runInAction(() => {
      this.doctorTagList = data
    })
    return data
  }

  @action public async getShare(params: any) {
    const { data } = await ContentApi.getShare(params)
    const timestamp: number = Date.now()
    runInAction(() => {
      this.shareInfo = { ...{}, ...data, ...{
        timestamp
      }}
    })
    return data
  }

  @action public async addDoctorTag(params: any) {
    const { data } = await ContentApi.addDoctorTag(params)
    return data
  }
}

export default ContentStore