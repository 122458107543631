import styled from 'styled-components'
import { Layout } from 'antd'
const { Sider } = Layout
export const LogoBox = styled.div`
	height: 64px;
	background: #5A96F9;
	padding: 16px;
	text-align: center;
`

export const SideWrapper = styled(Sider)`
	height: 100vh;
	box-sizing: border-box;
`
