import { action, observable, runInAction, toJS } from 'mobx'
import UserApi from '../apis/User'
import { ISignIn } from '../common/interface'
class LoginStore {
	@observable public token: string
	@observable public id: number
	@observable public userName: string
	@observable public userInfo: { [key: string]: any }
	@observable public Menus: any[] = []
	constructor() {
		this.id = 0
		this.token = ''
		this.userName = ''
		this.userInfo = {}
	}
	@action public async signIn(params: ISignIn) {
		try {
			const { data: res } = await UserApi.Login(params)
			window.sessionStorage.setItem('token', res.token)
			return Promise.resolve()
		} catch (error) {
			return Promise.reject(error)
		}
	}

	@action public async getUserInfo() {
		const { data: res } = await UserApi.GetUserInfo()
		this.id = res.userId
		delete res['userId']
		this.userInfo = res
		sessionStorage.setItem('userInfo', JSON.stringify(this.userInfo))
	}

	@action public async UpdatePassword(params: {
		newPwd: string
		oldPwd: string
		userId: number
	}) {
		try {
			await UserApi.UpdatePwd(params)
			return Promise.resolve()
		} catch (error) {
			return Promise.reject(error)
		}
	}
	@action public async getMenuData() {
		const { data: res } = await UserApi.GetUserResources()
		this.Menus = res
	}
}

export default LoginStore
